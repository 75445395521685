// Anzeige von Gruppendaten
// Created by Dr. Maximillian Dornseif 2020-09-03
// Copyright 2020, 2021, 2023 HUDORA

import React from 'react'
import { navigate } from 'wouter/use-location'

import { IEntitySubPage3 } from '../lib/components/ke/EntityPage3'
import { KindListe3 } from '../lib/components/ke/KindListe3'
import { KindPage3 } from '../lib/components/ke/KindPage3'
import {
  GRUPPEN_ARTNR_QUERY,
  GRUPPEN_MITGLIEDER_QUERY,
  GRUPPEN_PREISE_QUERY,
  GRUPPE_CREATE_MUTATION,
  GRUPPE_EDIT_MUTATION,
  GRUPPE_ENTITY_QUERY,
  GRUPPE_LIST_QUERY,
  GRUPPE_SUGGESTIONS_QUERY,
} from './StammGruppenAppQueries'
import {
  ARTNR_CREATE_MUTATION,
  ARTNR_EDIT_MUTATION,
  PREIS_CREATE_MUTATION,
  PREIS_EDIT_MUTATION,
} from './StammKundenAppQueries'

// Rendert entweder die Startseite (Suche) oder eine Kunden-/Gruppen-/Verbandsseite
export const GruppenApp = () => {
  const subPages: IEntitySubPage3[] = [
    {
      title: 'Mitglieder',
      path: 'm',
      render: (entity) => (
        <KindListe3
          schemaName={'HwKunde'}
          listQuery={GRUPPEN_MITGLIEDER_QUERY}
          listQueryVars={{ nr: entity.designator }}
          hideExcept={[
            'designator',
            'name',
            'bezeichner',
            'adresse.plz',
            'adresse.ort',
            'adresse.land',
            'adresse.iln',
          ]}
        />
      ),
    },
    // HwKundenArtNr Können auf Kunden oder Gruppen bezogen sein.
    {
      title: '(Kunden)Gruppenartikel№',
      path: 'artnr',
      render: (entity) => (
        <KindListe3
          listQuery={GRUPPEN_ARTNR_QUERY}
          listQueryVars={{ nr: entity.designator }}
          mutation={ARTNR_EDIT_MUTATION}
          createMutation={ARTNR_CREATE_MUTATION}
          createTemplate={{ kundennr: entity.designator }}
          schemaName="HwKundenArtNr"
          hideExcept={['designator', 'artnr', 'artnr_kunde', 'ist_aktiv', 'ist_gesperrt', 'name']}
          title="Gruppenartikelkonfiguration (siehe auch bei den Kunden)"
          rowToolsEnabled={true}
        />
      ),
    },
    // HwKundenPreis Können auf Kunden oder Gruppen bezogen sein.
    {
      title: '(Kunden)Gruppenpreise',
      path: 'preise',
      render: (entity) => (
        <KindListe3
          listQuery={GRUPPEN_PREISE_QUERY}
          listQueryVars={{ nr: entity.designator }}
          mutation={PREIS_EDIT_MUTATION}
          createMutation={PREIS_CREATE_MUTATION}
          createTemplate={{ kundennr: entity.designator }}
          schemaName="HwKundenPreis"
          title="Gruppepreise (siehe auch bei den Kunden)"
          // hideExcept={['designator', 'artnr', 'artnr_kunde', 'ist_aktiv', 'ist_gesperrt', 'name']}
          rowToolsEnabled={true}
          // bulkEditKeyField="artnr"
        />
      ),
    },
  ]

  return (
    <KindPage3
      suggestionQuery={GRUPPE_SUGGESTIONS_QUERY}
      basePath={'/stamm'}
      componentPath={'g'}
      schemaName={'HwKundenGruppe'}
      title={'Gruppen'}
      subPages={subPages}
      initialLimit={250}
      hideExcept={['ist_aktiv', 'designator', 'name', 'vertreter', 'branche']}
      entityQuery={GRUPPE_ENTITY_QUERY}
      listQuery={GRUPPE_LIST_QUERY}
      mutation={GRUPPE_EDIT_MUTATION}
      createMutation={GRUPPE_CREATE_MUTATION}
      entityCommandBarItems={[
        {
          key: 'wawi',
          text: 'in der Warenwirtschaft',
          ariaLabel: 'Gruppen-Warenwirtschaft',
          iconProps: {
            iconName: 'Packages',
          },
          iconOnly: false,
          onClick: (e) => {
            navigate(window.location.pathname.replace('/stamm/g/', '/wawi/g/'))
          },
        },
      ]}
    ></KindPage3>
  )
}
