// Anzeige von Verbandsdaten
// Created by Dr. Maximillian Dornseif 2020-09-07
// Copyright 2020 HUDORA

import gql from 'graphql-tag'

import { KUNDE_FRAGMENT } from '../fragments'
import { IEntitySubPage } from '../lib/components/EntityPage'
import { KindListe } from '../lib/components/KindListe'
import { KindPage } from '../lib/components/KindPage'

// Rendert entweder die Startseite (Suche) oder eine Kunden-/Gruppen-/Verbandsseite
export const VerbaendeApp = () => {
  const subPages: IEntitySubPage[] = [
    {
      title: 'Mitglieder',
      path: 'm',
      component: (
        <KindListe
          schemaName={'HwKunde'}
          listQuery={VERBANDS_MITGLIEDER_QUERY}
          title={''}
          hideExcept={[
            'designator',
            'name',
            'bezeichner',
            'adresse.plz',
            'adresse.ort',
            'adresse.land',
            'adresse.iln',
          ]}
        />
      ),
    },
  ]
  return (
    <KindPage
      basePath={'/stamm'}
      componentPath={'v'}
      initialLimit={1000}
      schemaName={'HwKundenVerband'}
      hideExcept={['ist_aktiv', 'designator', 'name']}
      title={'Verbände'}
      subPages={subPages}
      listQuery={LIST_QUERY}
      entityQuery={ENTITY_QUERY}
      editMutation={EDIT_MUTATION}
      createMutation={CREATE_MUTATION}
      suggestionQuery={SUGGESTIONS_QUERY}
    />
  )
}

const SUGGESTIONS_QUERY = gql`
  query SearchVerbandQuery($term: String!) {
    fulltextSearch: hwKundenVerbandSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`

export const VERBAND_FRAGMENT = gql`
  fragment verbandFragment on HwKundenVerband {
    id
    designator
    name
    ist_aktiv
    unsere_lieferantennr
    kreditlimit
    created_at
    updated_at
  }
`

const LIST_QUERY = gql`
  query AlleVerbaende($cursor: String) {
    nodes: hwKundenVerbandPages(after: $cursor) {
      edges {
        # cursor
        node {
          ...verbandFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${VERBAND_FRAGMENT}
`

const ENTITY_QUERY = gql`
  query EinVerbandQuery($nr: ID!) {
    entity: hwKundenVerband(designator: $nr) {
      ...verbandFragment
    }
  }
  ${VERBAND_FRAGMENT}
`

const CREATE_MUTATION = gql`
  mutation VerbandCreateMutation($input: HwKundenVerbandInput!) {
    entity: hwKundenVerbandCreate(input: $input) {
      ...verbandFragment
    }
  }
  ${VERBAND_FRAGMENT}
`

const EDIT_MUTATION = gql`
  mutation VerbandEditMutation($designator: ID!, $input: HwKundenVerbandInput!) {
    hwKundenVerbandUpdate(designator: $designator, input: $input) {
      ...verbandFragment
    }
  }
  ${VERBAND_FRAGMENT}
`

const VERBANDS_MITGLIEDER_QUERY = gql`
  query VerbandMitgliederMuitation($nr: ID!) {
    entity: hwKundenVerband(designator: $nr) {
      id
      nodes: kunden {
        ...kundeFragment
      }
    }
  }
  ${KUNDE_FRAGMENT}
`
