// Genehmigen / Freigeben von Aufträgen
// Created by Dr. Maximillian Dornseif 2020-10-05
// Copyright 2020, 2023 HUDORA

import { IContextualMenuItemStyles, getTheme } from '@fluentui/react'
import gql from 'graphql-tag'
import * as React from 'react'
import toast from 'react-hot-toast'
import { AnyVariables, OperationResult, useMutation } from 'urql'

import { AUFTRAG_FRAGMENT } from '../../fragments'
import { ICommandBarItemProps } from '../../lib/muipanels'

export function useGenehmigenGui(auftrag) {
  const theme = getTheme()
  const [disabled, setDisabled] = React.useState(false)
  const [, mutateEntity] = useMutation(GENEHMIGEN_MUTATION)

  // Styles for both command bar and overflow/menu items
  const importantItemStyle: Partial<IContextualMenuItemStyles> = {
    icon: { color: theme.palette.red },
    iconHovered: { color: theme.palette.redDark },
  }
  // TODO: use useToastMutation()
  const update = () => {
    setDisabled(true)
    const prom = async () => {
      const r = await mutateEntity({
        key: auftrag._keyStr,
        message: '',
      } as any)
      if (r.error) throw r.error
      return r
    }
    toast.promise(prom(), {
      loading: 'Auftrag Genehmigen',
      success: (r: OperationResult<any, AnyVariables>) => {
        setDisabled(false)
        return <React.Fragment>Erfolgreich genehmigt.</React.Fragment>
      },
      error: (e) => {
        setDisabled(false)
        console.error(e)
        return `Fehler: ${e}`
      },
    })
  }

  if (!auftrag || !(auftrag?.status > '200' && auftrag.status < '250')) {
    return []
  }

  const item: ICommandBarItemProps = {
    key: 'genehmigen',
    text: 'Genehmigen',
    ariaLabel: 'Genehmigen',
    iconOnly: false,
    iconProps: { iconName: 'PlayResume' },
    buttonStyles: importantItemStyle,
    disabled,
    onClick: () => update(),
  }
  return [item]
}

const GENEHMIGEN_MUTATION = gql`
  mutation GenehmigenMutation($key: ID!, $message: String) {
    updateHwAuftragGenehmigen(key: $key, message: $message) {
      auftrag {
        ...auftragFragment
      }
      success
      message
    }
  }
  ${AUFTRAG_FRAGMENT}
`
