// Anzeige von Produkten
// Created by Dr. Maximillian Dornseif 2020
// Copyright 2020, 2021, 2023 HUDORA

import { LinkRender } from '@hudora/hd-link'
import { DisplayZukunft, LadeChecker } from '@hudora/hd-react-components'
import { ValueRender } from '@hudora/react-jsonschema-valuerender'
import React from 'react'
import { useQuery } from 'urql'
import { navigate } from 'wouter/use-location'

import { PRODUKT_QUERY } from '../eAP/EapProdukteApp'
import { KindLightList } from '../eAP/KindLightList'
import { IEntitySubPage3 } from '../lib/components/ke/EntityPage3'
import { KindListe3 } from '../lib/components/ke/KindListe3'
import { KindPage3 } from '../lib/components/ke/KindPage3'
import { IEntity } from '../types'
import {
  AUFTRAG_LIST_QUERY,
  BESTAND_LIST_QUERY,
  PRODUKT_LIST_QUERY,
  PRODUKT_WITH_ITEM_QUERY,
  RESERVIERUNG_LIST_QUERY,
  SUGGESTIONS_QUERY,
  VERFUEGBARKEITS_PREIS_QUERY,
} from './WaWiProdukteAppQueries'

const SUB_PAGES: IEntitySubPage3[] = [
  {
    title: 'Aufträge',
    path: 'a',
    render: (entity) => (
      <KindListe3
        title={'offene Aufträge'}
        schemaName={'HwAuftragsPosition'}
        listQuery={AUFTRAG_LIST_QUERY}
        hideExcept={[
          'auftragsnr',
          'empfaengernr',
          'kunde.name',
          'bartnr',
          'menge_offen',
          'menge_geliefert',
          'menge_bestellt',
          'preis',
          'preisherkunft',
          'erledigt',
          'created_at',
          'updated_at',
        ]}
        listQueryVars={{ nr: entity.designator }}
      />
    ),
  },
  {
    title: 'Blockaufträge',
    path: 'r',
    render: (entity) => (
      <KindListe3
        title={'offene Blockaufträge'}
        schemaName={'HwReservierungsPosition'}
        listQuery={RESERVIERUNG_LIST_QUERY}
        listQueryVars={{ nr: entity.designator }}
      />
    ),
  },
  {
    title: 'Bestände',
    path: 'bestand',
    render: (entity) => (
      <KindListe3
        className="authEnforced"
        listQuery={BESTAND_LIST_QUERY}
        schemaName={'HwIcBestandsbuchSumme'}
        listQueryVars={{ nr: entity.designator }}
        hideExcept={[
          'lager',
          'sku',
          'lager_menge',
          'lager_wert',
          'adw',
          'zugangsmenge',
          'abgangsmenge',
          'bewegungen',
          'erster_zugang',
          'letzter_abgang',
          'gebucht_bis_ts',
        ]}
      />
    ),
  },
]
export const WaWiProdukteApp = () => {
  return (
    <div>
      <p>
        Produkte sind Objekte, die so im Katalog stehen und einen Preis haben. Z.B. Inliner Kids in
        verschiedenen Farben und Größen. Produkte haben ein oder mehrere zugehörige Items.
      </p>
      <KindPage3
        basePath={'/wawi'}
        componentPath={'prod'}
        initialLimit={1000}
        schemaName="H3Produkt"
        title={'Produkte'}
        hideExcept={[
          'ist_aktiv',
          'designator',
          'name',
          'kategorie',
          'einheit',
          'istSperrgut',
          'ztNr',
          'created_at',
          'updated_at',
        ]}
        subPages={SUB_PAGES}
        listQuery={PRODUKT_LIST_QUERY}
        entityQuery={PRODUKT_QUERY}
        suggestionQuery={SUGGESTIONS_QUERY}
        entityCommandBarItems={[
          {
            key: 'eap',
            text: 'im eAP',
            ariaLabel: 'Produkt-eAP',
            iconProps: {
              iconName: 'Packages',
            },
            iconOnly: false,
            onClick: (e) => {
              navigate(window.location.pathname.replace('/wawi/prod/', '/eap/prod/'))
            },
          },
        ]}
        renderEntityPageBottom={(entity) => (
          <React.Fragment>
            <DisplayProduktPreiseUndVerfuegbarkeiten entity={entity} />
            <DisplayItems entity={entity} />
          </React.Fragment>
        )}
      ></KindPage3>
    </div>
  )
}

export const DisplayProduktPreiseUndVerfuegbarkeiten = (props: { entity: any; highlight?: string }) => {
  const [{ saving: loading, data, error }] = useQuery({
    query: VERFUEGBARKEITS_PREIS_QUERY,
    variables: { nr: props.entity?.designator },
    skip: !props.entity?.designator,
  })

  return (
    <div>
      <h3>Preise &amp; Verfügbarkeiten</h3>
      <LadeChecker loading={loading} error={error}>
        <table className="hd-mytable">
          <thead>
            <tr>
              <th>№</th>
              <th>Name</th>
              <th>FH</th>
              <th>UVP</th>
              <th>frei</th>
            </tr>
          </thead>
          <tbody>
            {data?.h3Produkt?.itemList.map((x) =>
              x?.preis?.fh || x?.zukunft?.freie_menge !== undefined ? (
                <tr
                  key={x?.designator}
                  style={{
                    fontWeight:
                      props.highlight?.length > 1 && x?.designator?.startsWith(props.highlight) ? 'bold' : '',
                  }}
                >
                  <td>
                    <LinkRender value={x?.designator} />
                  </td>
                  <td>{x?.name}</td>
                  <td>
                    <ValueRender value={x?.preis?.fh} format="eurocent" />
                  </td>
                  <td>
                    <ValueRender value={x?.preis?.uvp} format="eurocent" />
                  </td>
                  <td>
                    <DisplayZukunft zukunft={x?.zukunft} />
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </LadeChecker>
    </div>
  )
}

export const DisplayItems = (props: { entity: any }) => {
  const [{ fetching: loading, data, error }] = useQuery({
    query: PRODUKT_WITH_ITEM_QUERY,
    variables: { nr: props.entity?.designator },
    skip: !props.entity?.designator,
  })
  console.log(data)
  const entity = data?.entity
  return (
    <LadeChecker loading={loading} error={error}>
      <h3>Zugehörige Items</h3>
      <KindLightList
        entities={entity?.itemList as Array<IEntity>}
        schemaName={'H3Item'}
        hideExcept={[
          'designator',
          'zusatz',
          'komponenten',
          'ean',
          'ist_aktiv',
          'kunde',
          'updated_at',
          'created_at',
        ]}
      />
    </LadeChecker>
  )
}
