// Anzeige von Produkten. Wie KindTable, aber ohne AG-Grid
// Sollte möglichst weit wie <SchemaEntityDisplay />
// und <ValueRender /> im Aussehen entsprechen.
// Created by Mehrdad Mohammadzadeh 2021
// Copyright 2021, 2021 HUDORA

import '../index.css'

import { IColumn } from '@fluentui/react/lib/DetailsList'
import { LinkRender } from '@hudora/hd-link'
import { useSchema } from '@hudora/hd-react-components'
import { assertIsArray, assertIsObject } from 'assertate-debug'
import { JSONSchema7 } from 'json-schema'
import get from 'lodash.get'
import * as R from 'ramda'
import React from 'react'

import { IEntity } from '../types'

export function KindLightList(props: { entities: IEntity[]; schemaName: string; hideExcept: string[] }) {
  const { schema } = useSchema(props.schemaName)
  const columns = _buildColumnDefs(props.entities, schema, props.hideExcept)
  return !props.entities ? (
    <p>leer</p>
  ) : (
    <>
      <table className="hd-mytable">
        <>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.name}>{column.name}</th>
              ))}
            </tr>
          </thead>
          <KindListLightInner cols={columns} prps={props} />
        </>
      </table>
    </>
  )
}

function _buildColumnDefs(rows: IEntity[], schema: JSONSchema7, hideExcept?: string[]): Array<IColumn> {
  if (!rows || rows.length < 1 || R.isEmpty(schema)) {
    return []
  }
  assertIsArray(rows)
  const sortlisten: string[][] = hideExcept ? [hideExcept] : []

  // Die Rohddaten sind unsere Grundlage
  const columnDefDict = _extractColDefFromRow(rows[0])
  sortlisten.push(_extractColDefFromSchema(schema, columnDefDict))

  for (const colDef of Object.values(columnDefDict)) {
    if (hideExcept) {
      colDef.data.hide = !hideExcept.includes(colDef.fieldName)
    }
  }

  // Jetzt müssen wir die Spalten noch in die richtige Reihenfolge
  // bringen. Apollo Client liefert die Spalten nicht (mehr) in
  // der Reihenfolge azurück, wie sie in der Query stehen.
  // sortlisten ist eine Liste mit Listen von Spaltennamen - die
  // arbeiten wir nacheinander ab
  sortlisten.push(Object.keys(rows[0]))

  const columnDefs: Array<IColumn> = []
  for (const sortliste of sortlisten) {
    for (const colname of sortliste) {
      if (columnDefDict[colname] !== undefined) {
        columnDefDict[colname].isCollapsible = columnDefDict[colname].data.hide === true
        if (!columnDefDict[colname].data.hide) {
          columnDefs.push(columnDefDict[colname])
        }
        columnDefDict[colname] = undefined
      }
    }
  }

  return columnDefs
}
function _extractColDefFromRow(
  row: Record<string, any>,
  columnDefDict: Record<string, IColumn> = {},
  prefix = ''
): Record<string, IColumn> {
  if (!row) {
    return {}
  }
  for (const key of Object.keys(row)) {
    if (row?.[key]?.__typename) {
      // Nesting happens
      _extractColDefFromRow(row?.[key], columnDefDict, `${prefix}${key}.`)
    } else {
      const colDef: IColumn = {
        fieldName: `${prefix}${key}`,
        key: `${prefix}${key}`,
        minWidth: 16,
        maxWidth: 128,
        name: key,
        isResizable: true,
        data: { hide: false, type: 'string', tooltip: '', isLink: false },
      }
      if (key.startsWith('_') || key === 'id') {
        colDef.data.hide = true
      }
      if (key === 'designator') {
        colDef.isRowHeader = true
        colDef.data.isLink = true
      }
      if (key.endsWith('_at')) {
        colDef.data.type = 'date-time'
      }
      if (!key.startsWith('_')) {
        columnDefDict[`${prefix}${key}`] = colDef
      }
    }
  }
  return columnDefDict
}
function _extractColDefFromSchema(
  schema: JSONSchema7,
  columnDefDict: Record<string, IColumn> = {},
  prefix = ''
): string[] {
  const feldnamen: string[] = []

  if (!schema) {
    return feldnamen
  }

  assertIsObject(schema.properties)
  for (const key of Object.keys(schema.properties)) {
    // Jetzt die Spalten mit den Daten aus dem Schema aufbereiten
    feldnamen.push(`${prefix}${key}`)
    const schemaProp = get(schema.properties, key)

    if (schemaProp === true || schemaProp === false) {
      // type guard
      continue
    }

    if (!schemaProp) {
      console.log(`${key} gibt es nicht`, schemaProp, get(schema.properties, key), 'in', schema)
      continue
    }

    if (schemaProp.type === 'object') {
      // Nesting happens
      _extractColDefFromSchema(schemaProp, columnDefDict, `${prefix}${key}.`)
      continue
    }

    const colDef = columnDefDict[`${prefix}${key}`]

    if (colDef !== undefined) {
      colDef.data.headerTooltip = `${key}: ${schemaProp.description}`
      colDef.data.type = schemaProp.format || schemaProp.type
      colDef.name = schemaProp.title
      // Ab hier sollten wir wie in <SchemaEntityDisplay />
      // und <ValueRender /> funktionieren.
      if (schemaProp.format === 'eurocent' || schemaProp.format === 'eurocentlarge') {
        colDef.name = `${schemaProp.title} €`
      }

      if (schemaProp?.title?.endsWith('№')) {
        colDef.data.isLink = true
      }
    }
  }

  return feldnamen
}

export const KindListLightInner: any = ({ cols, prps }) => {
  const ganzlist = []
  prps.entities.map((entity) => {
    const ordList = []
    for (let i = 0; i < cols.length; i++) {
      if (cols[i].data.isLink) {
        ordList.push(<LinkRender key={cols[i].fieldName} value={entity[`${cols[i].fieldName}`]} />)
      } else {
        if (entity[`${cols[i].fieldName}`] === true) {
          ordList.push(<span key={cols[i].fieldName + 'ok'}>✅</span>)
        } else if (entity[`${cols[i].fieldName}`] === false) {
          ordList.push(<span key={cols[i].fieldName + 'nope'}>❌</span>)
        } else {
          ordList.push(<span key={cols[i].fieldName + 'pope'}>{entity[`${cols[i].fieldName}`]}</span>)
        }
      }
    }
    ganzlist.push(ordList)
    return 0
  })
  return (
    <tbody>
      {ganzlist.map((element, index1) => (
        <tr key={index1}>
          {element.map((item, index2) => (
            <td key={index2}>{item}</td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
