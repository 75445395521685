/** Stellt sicher, dass der USer eingeloggt ist und
 * sorgt dafür, dass die Daten viia useAuth0 bereit gestellt sind.
 * */

import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'

function AuthenticatingAppInner(props: { children: any }) {
  return props.children
}

export const AuthenticatingApp = withAuthenticationRequired(AuthenticatingAppInner, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page…</div>,
})
