// Formatierung von BigQuery Daten.
// Created by Maximillian Dornseif 2020-09
// (c) Maximillian Dornseif

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Label } from '@fluentui/react/lib/Label';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';
import { FehlerChecker, LadeChecker } from '@hudora/hd-react-components';
import { useTitle } from 'fluentui-hooks';
import gql from 'graphql-tag';
import find from 'lodash.find';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAgGrid } from 'react-use-aggrid-enterprise';

import { buildColumnDefs } from './BigQueryFormat';
import { BigQueryHdcTableWithSqlPanel } from './BigQueryHdcTableWithSqlPanel';

// The initial backoff time after a disconnection occurs, in seconds.
const MINIMUM_BACKOFF_TIME = 2;
// The maximum backoff time before giving up, in seconds.
const MAXIMUM_BACKOFF_TIME = 600;

export const SQL_QUERY = gql`
  query SqlQuery($sqlName: String!) {
    sqlQuery(name: $sqlName) {
      id
      nextUrl
    }
  }
`;

export interface IBigQueryViewProps {
  sqlName: string;
  title: string;
  initialLayout?: { [key: string]: any };
}
export function BigQueryView(props: IBigQueryViewProps) {
  const { api, onGridReady } = useAgGrid();
  const [headers, setHeaders] = useState({});
  // const [layout, setLayout] = useState({})
  const [urlToFetch, setUrlToFetch] = useState(undefined);
  const [columnDefs, setColumnDefs] = useState([]);
  const [fetchError, setFetchError] = useState(undefined);
  const [tableRows, setTableRows] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const { loading, error, data } = useQuery(SQL_QUERY, {
    variables: { sqlName: props.sqlName },
    onCompleted: (newData) => setTimeout(() => fetchMoreData(newData)),
  });
  const { getAccessTokenSilently } = useAuth0();

  function fetchMoreData(newData) {
    setUrlToFetch(newData.sqlQuery.nextUrl);
  }

  // Wir brauchen ein Token zur HTTP Authentifizierung
  useEffect(() => {
    async function buildClient() {
      let token = null;
      try {
        token = await getAccessTokenSilently().catch((e) =>
          alert(`Kann Authentifizierung nicht durchführen: ${e}.`)
        );
      } catch (error) {
        console.error(
          'Obwohl Sie eigentlich angemeldet sein sollten, kann ich keine Zugriffsberechtigung einlesen.',
          error
        );
        throw error;
      }
      setHeaders({
        authorization: token ? `Bearer ${token}` : '',
      });
    }
    buildClient();
  }, [getAccessTokenSilently]);

  useTitle(`${props.title} (${props.sqlName})`);

  if (urlToFetch !== undefined && headers) {
    console.log('fetching', urlToFetch);
    handleFetch().catch((e) => {
      setFetchError(e);
    });
  }

  async function handleFetch() {
    const url = urlToFetch;
    setUrlToFetch(undefined);
    const response = await fetch(`${process.env.REACT_APP_REST_URL}${urlToFetch}`, {
      headers,
    }).catch((e) => {
      setFetchError(e);
    });

    if (!response || response.status === 408) {
      // Server antwortet nicht oder bittet us, es noch mal zu versuchen
      // Zeit für `Truncated exponential backoff`
      // wait time is min(((2^n)+random_number_milliseconds), maximum_backoff)
      const backoffTimeMS = calculateBackoff(retryCount);
      setTimeout(() => setUrlToFetch(url), backoffTimeMS);
      setRetryCount(retryCount + 1);
      return;
    }

    if (response.ok) {
      const body = await response.json();
      setRetryCount(0);

      if (columnDefs.length < 1) {
        setColumnDefs(applyInitialLayoutToColumnDefs(props.initialLayout, buildColumnDefs(body.fields)));
      }

      // filter werden beim Laden von Daten doof überschrieben
      if (api && columnDefs?.length !== 0) {
        const filterModel = api?.getFilterModel();
        api.applyTransaction({ add: body.rows });
        if (Object.keys(filterModel)?.length === 0) {
          api.setFilterModel(filterModel);
        } else {
          api.setFilterModel(props?.initialLayout?.filterModel);
        }
      } else {
        // initial data
        setTableRows([...tableRows, ...body.rows]);
        if (api) {
          api.sizeColumnsToFit();
          api.setFilterModel(props?.initialLayout?.filterModel);
        }
      }
      if (body.nextUrl !== '') {
        if (body.nextUrl === url) {
          throw Error('Requesting the same URL twice');
        }
        // schedule next fetch somewhat later
        setTimeout(() => setUrlToFetch(body.nextUrl), 150);
      } else {
        // we are finished fetching, reapply filters
        if (api) {
          api.sizeColumnsToFit();
          api.setFilterModel(props?.initialLayout?.filterModel);
        }
      }
    } else if (response.status === 408) {
      // Server asks us to retry
    } else {
      // !response.ok
      setFetchError(new Error(response.statusText));
    }
  }

  return (
    <>
      <h1>{props.title}</h1>
      {error ? `Fehler beim Zugriff auf ${props.sqlName}.sql: ${error}` : null}
      <LadeChecker loading={loading} error={error} />
      {fetchError ? `Fehler beim Zugriff auf ${props.sqlName}.sql: ${fetchError}` : null}
      <LadeChecker loading={loading} error={fetchError} />
      {loading ? (
        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: '1em' }}>
          <Label>Initialisiere BigQuery</Label>
          <Spinner size={SpinnerSize.xSmall} />
        </Stack>
      ) : null}
      {!loading && columnDefs.length < 1 ? (
        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: '1em' }}>
          <Label>
            {`Rufe die ersten Daten ab; Schritt ${retryCount}`}{' '}
            {retryCount
              ? `; warte ${Number(calculateBackoff(retryCount) / 1000).toFixed(0)} s bis zum nächsten Versuch`
              : '; Abfrage läuft'}
          </Label>
          <Spinner size={SpinnerSize.xSmall} />
        </Stack>
      ) : null}
      <BigQueryHdcTableWithSqlPanel
        sqlName={props.sqlName}
        jobId={data?.sqlQuery?.id || ''}
        onGridReady={onGridReady}
        // onLayoutChange={setLayout}
        title={props.title}
        initialLayout={props?.initialLayout}
        columnDefs={columnDefs}
        rowData={tableRows}
        gridOptions={{
          columnTypes: {
            date: {},
            euroType: {},
            float: {},
            integer: {},
            string: {},
          },
        }}
      />
    </>
  );
}
BigQueryView.propTypes = {
  sqlName: PropTypes.string.isRequired,
  initialLayout: PropTypes.object,
  initialFilterModel: PropTypes.object,
  title: PropTypes.string,
  path: PropTypes.string,
};

function calculateBackoff(retryCount) {
  const backoffTimeMS =
    Math.min(2 ** retryCount + Math.random() / 0.2 + MINIMUM_BACKOFF_TIME, MAXIMUM_BACKOFF_TIME) * 1000;
  return backoffTimeMS;
}

function applyInitialLayoutToColumnDefs(initialLayout, sqlColumnDefs) {
  const cd = initialLayout === undefined ? [] : [...initialLayout.columnState];
  for (const col of sqlColumnDefs) {
    const existing = find(cd, (o) => o.colId === col.colId);
    if (existing === undefined) {
      // not in layout
      cd.push({ ...col, hide: initialLayout !== undefined });
    } else {
      // merge the two
      Object.keys(col).forEach((key) => {
        if (existing[key] === undefined) {
          existing[key] = col[key];
        }
      });
    }
  }
  return cd;
}

export function prettySize(bytes, separator = ' ', postFix = '') {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.min(parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10), sizes.length - 1);
    return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
  }
  return 'n/a';
}
