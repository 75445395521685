// Anzeige von Items (Artikel) für die Warenwirtschaft
// Created by Dr. Maximillian Dornseif 2020
// Copyright 2020-2023 HUDORA

import { Dialog } from '@fluentui/react'
import { Stack } from '@fluentui/react/lib/Stack'
import { LinkRender } from '@hudora/hd-link'
import { useDialog } from 'fluentui-hooks'
import gql from 'graphql-tag'
import React from 'react'
import ReactJson from 'react-json-view'
import { useQuery } from 'urql'
import { navigate } from 'wouter/use-location'

import { PdfDialogInner } from '../components/PdfDialog'
import {
  ITEM_FRAGMENT,
  ITEM_PIM_FRAGMENT,
  PIM_FRAGMENT,
  PRODUKT_FRAGMENT,
  SKUM_FRAGMENT,
  SKUM_PIM_FRAGMENT,
  SKUSUM_FRAGMENT,
  SKU_FRAGMENT,
  SKU_PIM_FRAGMENT,
} from '../fragments'
import { IEntitySubPage } from '../lib/components/EntityPage'
import { EntityRender } from '../lib/components/EntityRender'
import { EntitySubDisplay } from '../lib/components/EntitySubDisplay'
import { KindPage } from '../lib/components/KindPage'
import { LadeChecker } from '../lib/components/UiChecker'

export const ItemsApp = () => {
  const [openPdfDialog, pdfDialogProps, , dismissDialog] = useDialog(
    'Partnernet-Check rendern',
    'Bitte wählen Sie ein passendes Template und Ausgabeformat.'
  )
  const subPages: IEntitySubPage[] = [
    {
      title: 'IIM (PIM)',
      path: 'pim',
      component: (
        <EntitySubDisplay
          key="PIM"
          nr="?"
          schemaName="H3ItemInfo"
          entityQuery={PIM_QUERY}
          editMutation={ITEM_PIM_MUTATION}
        />
      ),
    },
    {
      title: 'Itemdaten',
      path: 'i',
      component: <FullItem />,
    },
  ]

  return (
    <section className="ItemsApp">
      <KindPage
        basePath={'/eap'}
        componentPath={'item'}
        initialLimit={1000}
        schemaName={'H3Item'}
        title={'Items (Artikel)'}
        subPages={subPages}
        listQuery={ITEM_LIST_QUERY}
        entityQuery={ITEM_QUERY}
        editMutation={ITEM_MUTATION}
        suggestionQuery={SUGGESTIONS_QUERY}
        entityCommandBarItems={[
          {
            key: 'warenwirtschaft',
            text: 'in der Warenwirtschaft',
            ariaLabel: 'Item-Warenwirtschaft',
            iconProps: {
              iconName: 'Packages',
            },
            iconOnly: false,
            onClick: (e) => {
              navigate(window.location.pathname.replace('/eap/item/', '/wawi/item/'))
            },
          },
          {
            key: 'pdfrender',
            text: 'Partnernet-Check rendern',
            ariaLabel: 'Partnernet-Check rendern',
            iconProps: { iconName: 'PenWorkspace' },
            onClick: openPdfDialog,
          },
        ]}
      >
        <DisplayItemDetails
          key="DisplaySkus"
          entity={{}}
          nr={''}
          dismissDialog={dismissDialog}
          pdfDialogProps={pdfDialogProps}
        />
      </KindPage>
    </section>
  )
}

function DisplayItemDetails(props) {
  const [result1] = useQuery({ query: ITEM_WITH_MASTER, variables: { nr: props.nr } })
  const { fetching: loading, data, error } = result1
  const [result2] = useQuery({ query: FULL_ITEM_QUERY, variables: { nr: props.nr } })
  const { data: fullItemData } = result2
  const entity = data?.entity

  return (
    <LadeChecker loading={loading} error={error}>
      <>
        <hr />
        <Stack tokens={{ childrenGap: '1em' }}>
          <DisplayImage entity={entity} />
          <section className="errechnet">
            Errechnete Werte:
            <code>{JSON.stringify(entity?.skuSum, null, ' ')}</code>
          </section>
          <section className="skus">
            Am Lager als:
            <Stack horizontal tokens={{ childrenGap: '2em' }}>
              {entity?.skuMasterList?.map((master) => (
                <div key={master.designator}>
                  <div>
                    {master.zusatz} {master.eanEx}{' '}
                    <b>
                      <LinkRender value={master.designator} />
                    </b>
                  </div>
                  {master?.skuList?.map((sku) => (
                    <div key={sku?.designator}>
                      {sku?.ist_aktiv ? (
                        <EntityRender entity={sku} schemaName="H3Sku" />
                      ) : (
                        <h3>
                          <LinkRender value={sku?.designator} />
                        </h3>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </Stack>
          </section>
          <ReactJson name="Details" collapsed={true} displayObjectSize={false} src={{ entity }} />
          <Dialog {...props.pdfDialogProps}>
            <PdfDialogInner
              data={{ ...fullItemData?.entity, belegart: 'PartnernetCheckItem' }}
              onDismiss={props.dismissDialog}
            ></PdfDialogInner>
          </Dialog>
        </Stack>
      </>
    </LadeChecker>
  )
}

function DisplayImage(props) {
  const layout = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    backgroundImage: `url(${props.entity.imageUrl})`,
    backgroundSize: 'contain',
    width: '15em',
    height: '15em',
    position: 'absolute' as any,
    right: 0,
  }
  return props.entity.imageUrl ? <div style={layout}></div> : null
}

const SUGGESTIONS_QUERY = gql`
  query ItemsSearch2Query($term: String!) {
    fulltextSearch: h3ItemSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`

export const ITEM_LIST_QUERY = gql`
  query ItemListQuery($cursor: String) {
    nodes: h3ItemPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        cursor
        node {
          ...itemFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ITEM_FRAGMENT}
`

export const ITEM_QUERY = gql`
  query ItemQuery($nr: ID!) {
    entity: h3Item(designator: $nr) {
      ...itemFragment
    }
  }
  ${ITEM_FRAGMENT}
`

export const ITEM_WITH_MASTER = gql`
  query ItemEtc2Query($nr: ID!) {
    entity: h3Item(designator: $nr) {
      ...itemFragment
      imageUrl

      skuMasterList {
        ...skuMasterFragment
        skuList {
          ...skuFragment
        }
      }
      skuSum {
        ...skuSumFragment
      }
    }
  }
  ${ITEM_FRAGMENT}
  ${SKUM_FRAGMENT}
  ${SKU_FRAGMENT}
  ${SKUSUM_FRAGMENT}
`
export const ITEM_MUTATION = gql`
  mutation h3ItemMutation($designator: ID!, $input: H3ItemInput!) {
    entity: h3ItemUpdate(designator: $designator, input: $input) {
      ...itemFragment
    }
  }
  ${ITEM_FRAGMENT}
`

const PIM_QUERY = gql`
  query ItemPimLesenQuery($nr: ID!) {
    h3Item(designator: $nr) {
      id
      designator
      entity: pim {
        ...itemInfoFragment
      }
    }
  }
  ${ITEM_PIM_FRAGMENT}
`

export const ITEM_PIM_MUTATION = gql`
  mutation ItemPimAendernMutation($designator: ID!, $input: H3ItemInfoInput!) {
    h3ItemInfoUpdate(designator: $designator, input: $input) {
      ...itemInfoFragment
    }
  }
  ${ITEM_PIM_FRAGMENT}
`

function FullItem(props) {
  // "Itemdaten" genannt.
  const [result] = useQuery({ query: FULL_ITEM_QUERY, variables: { nr: props.nr } })
  const { fetching: loading, error, data } = result
  return (
    <div>
      <h2>Komplette Produktdaten bis hin zur SKU</h2>
      <LadeChecker loading={loading} error={error} label={`Lade Beleg ${props.nr}`}>
        <ReactJson
          src={data?.entity}
          name="Produktdaten"
          collapsed={4}
          collapseStringsAfterLength={100}
          displayDataTypes={false}
          iconStyle="square"
        />
      </LadeChecker>
    </div>
  )
}

export const FULL_ITEM_QUERY = gql`
  query itemMitProduktEtcLesen($nr: ID!) {
    entity: h3Item(designator: $nr) {
      imageUrl
      ...itemFragment
      pim {
        ...itemInfoFragment
      }
      produkt {
        ...produktFragment
        pim {
          ...produktInfoFragment
        }
      }
      skuMasterList {
        ...skuMasterFragment
        pim {
          ...skuMasterInfoFragment
        }
        skuNeuste {
          ...skuFragment
          pim {
            ...skuInfoFragment
          }
        }
        skuList {
          ...skuFragment
          pim {
            ...skuInfoFragment
          }
        }
      }
      skuSum {
        ...skuSumFragment
      }
    }
  }

  ${PRODUKT_FRAGMENT}
  ${PIM_FRAGMENT}
  ${ITEM_FRAGMENT}
  ${ITEM_PIM_FRAGMENT}
  ${SKUM_FRAGMENT}
  ${SKUM_PIM_FRAGMENT}
  ${SKU_FRAGMENT}
  ${SKU_PIM_FRAGMENT}
  ${SKUSUM_FRAGMENT}
`
