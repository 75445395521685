// Anzeige von Belegen
// Created 2021-03-24 by Dr. Maximillian Dornseif
// Copyright HUDORA 2021-2023

import { LadeChecker } from '@hudora/hd-react-components'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useQuery } from 'urql'
import { StringParam, useQueryParam } from 'use-query-params'

import { BELEG_FRAGMENT } from '../fragments'
import { KindPage3 } from '../lib/components/ke/KindPage3'
import { BelegEiner } from './BelegEiner'

export const BelegeApp = (props) => {
  return <Beleganzeige />
}

function Beleganzeige() {
  const [belegartSP, setBelegartSP] = useQueryParam('belegart', StringParam)
  const [belegart, setBelegart] = useState<string | null>(belegartSP)

  const handleBelegart = (event: React.MouseEvent<HTMLElement>, art: string | null) => {
    setBelegartSP(art || undefined)
    setBelegart(art || undefined)
  }
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="center">
        <ToggleButtonGroup value={belegart} exclusive onChange={handleBelegart} aria-label="text alignment">
          <ToggleButton value="Bestellbestätigung" aria-label="left aligned">
            AB-Bestellung
          </ToggleButton>
          <ToggleButton value="Auftragsbestätigung" aria-label="centered">
            AB-Auftrag
          </ToggleButton>
          <ToggleButton value="Proformarechnung" aria-label="right aligned">
            Proforma
          </ToggleButton>
          <ToggleButton value="Zollrechnung" aria-label="justified">
            Zollrg
          </ToggleButton>
          <ToggleButton value="Auftragsstatus" aria-label="justified">
            AB-Status
          </ToggleButton>
        </ToggleButtonGroup>

        <Divider orientation="vertical" flexItem />

        <ToggleButtonGroup value={belegart} exclusive onChange={handleBelegart} aria-label="text alignment">
          <ToggleButton value="Kommissionierbeleg" aria-label="justified">
            LK
          </ToggleButton>
          <ToggleButton value="Vorablieferschein" aria-label="justified">
            LV
          </ToggleButton>
          <ToggleButton value="Rückmeldung" aria-label="justified">
            LR
          </ToggleButton>
          <ToggleButton value="Lieferschein" aria-label="justified">
            LS
          </ToggleButton>
        </ToggleButtonGroup>

        <Divider orientation="vertical" flexItem />

        <ToggleButtonGroup value={belegart} exclusive onChange={handleBelegart} aria-label="text alignment">
          <ToggleButton value="Rechnung" aria-label="justified">
            RE
          </ToggleButton>
          <ToggleButton value="Sammelrechnung" aria-label="justified">
            RE-Sammel
          </ToggleButton>
          <ToggleButton value="Rechnungskorrektur" aria-label="justified">
            RK
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <KindPage3
        basePath={''}
        componentPath={'belege'}
        schemaName={'BBeleg'}
        title={'Belege'}
        listQuery={BELEG_LIST_QUERY_FILTERED}
        listQueryOptions={{ variables: { belegart } }}
        entityQuery={BELEG_QUERY}
        hideExcept={['designator', 'belegart', 'belegdatum', 'kundennr', 'empfaenger', 'created_at']}
        suggestionQuery={SUGGESTIONS_QUERY}
        renderEntityPage={(entity) => <BelegEinerOuter entity={entity} designator={entity?.designator} />}
      />
    </Stack>
  )
}

export const BelegEinerOuter = (props) => {
  const {
    fetching: loading,
    error,
    data,
  } = useQuery({ query: BELEG_QUERY, variables: { nr: props.designator }, pause: !props.designator })
  return (
    <LadeChecker loading={loading} error={error} label={`Lade Beleg ${props.nr}`}>
      <BelegEiner
        entity={JSON.parse(props?.entity?.raw)}
        pdf={props?.entity?.pdfUrl}
        raw={props?.entity?.raw}
      />
    </LadeChecker>
  )
}
export const BELEG_QUERY = gql`
  query BelegAnzeige($nr: ID!) {
    entity: beleg(designator: $nr) {
      ...belegFragment
      pdfUrl
      raw
    }
  }
  ${BELEG_FRAGMENT}
`
export const BELEG_DELIVERY_QUERY = gql`
  query BelegAnzeige($nr: ID!) {
    entity: beleg(designator: $nr) {
      ...belegFragment
      deliveryStatus {
        id
        created_at
        updated_at
        forDesignator
        info {
          art
          started_at
          finished_at
          deliveries {
            at
            type
            destination
            version
          }
        }
      }
    }
  }
  ${BELEG_FRAGMENT}
`

const SUGGESTIONS_QUERY = gql`
  query SearchBelegeQuery($term: String!) {
    fulltextSearch: belegSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`
export const BELEG_LIST_QUERY = gql`
  query Belege($cursor: String) {
    nodes: belegPages(after: $cursor) {
      edges {
        cursor
        node {
          ...belegFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${BELEG_FRAGMENT}
`

export const BELEG_LIST_QUERY_FILTERED = gql`
  query BelegeGefiltert($cursor: String, $belegart: String) {
    nodes: h3BelegPages(
      after: $cursor
      filter: { belegart: { eq: $belegart } }
      sort: { fields: [belegdatum], order: [DESC] }
    ) {
      edges {
        cursor
        node {
          _keyStr
          id
          designator
          belegart
          belegdatum
          empfaenger
          gruppennr
          kundennr
          name
          created_at
          updated_at
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
