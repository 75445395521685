// Formular für EntityMutate
// basiert auf https://github.com/rjsf-team/react-jsonschema-form
//
// Created by Dr. Maximillian Dornseif 2021-09-27
// Copyright 2021, 2023 Dr. Maximillian Dornseif

import Form from '@hudora/hd-rjsf'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { assertIsObject, assertIsString } from 'assertate-debug'
import { cleanDiff, cleanGqlInput } from 'graphql-clean-diff'
import isPlainObject from 'is-plain-obj'
import { JSONSchema7 } from 'json-schema'
import { jsonEmptyStrings } from 'json-schema-empty-strings'
import { jsonSchemaDataMerge } from 'json-schema-prepare-data-for-form'
import { removeReadonly } from 'json-schema-remove-readonly-from-data'
import mergeWith from 'lodash.mergewith'
import transform from 'lodash.transform'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import ReactJson from 'react-json-view'

import { IEntity } from '../../../types'
import { dataFitsSchema } from '../../util/jsonschema'
import { prepareEntityForForm } from '../prepareEntityForForm'

export const EntityMutateForm3 = (props: {
  schema: JSONSchema7
  entity?: Partial<IEntity>
  onSave: (entity: IEntity) => void
  children?: React.ReactElement<any>
  disabled?: boolean
}) => {
  assertIsString(props.schema.title, 'schema.title')
  assertIsObject(props.entity, 'entity')
  // Initiale Formulardaten - wenn wir ein neues Entity übergeben bekommen,
  // müssten wir das neu initialisieren

  // Defaults aus dem JSON Schema.
  const { entity: entityWithDefaults, cleanEntity } = prepareEntityForForm(props.schema, props.entity)
  const [currentFormData, setCurrentFormData] = useState<any>(entityWithDefaults)
  useEffect(() => {
    // We are called for a new entity, replace defaults
    // if (props.entity.designator !== currentFormData.designator) {
    setCurrentFormData(entityWithDefaults)
    // }
    // Das finde ich alles etwas fishy, muss ich zugeben
  }, [props.entity])

  if (!props.schema || !props.entity) {
    return null
  }

  // Aktuelle Änderungen: was wurde geändert und ist das "valid"?
  const valid = dataFitsSchema(currentFormData, props.schema)
  // das diff müssen wir gegen die Original-Input-Daten anwenden;
  // in `entity` sind ja schon die Defaults verarbeitet.
  const [diffVal, changedInfo] = _getDiff(props.entity, currentFormData, props.schema)

  const onChangeHandler = ({ formData }) => {
    setCurrentFormData(formData)
  }
  return (
    <section aria-label="Datensatz bearbeiten">
      <Stack sx={{ width: '100%' }} spacing={2}>
        <>
          <Form schema={props.schema} formData={currentFormData} liveValidate onChange={onChangeHandler}>
            {!valid ? (
              <Alert severity="warning">
                {' '}
                Die Daten entsprechen nicht dem erwarteten Schema (siehe oben).
              </Alert>
            ) : null}
            <div data-testid="editpanelbutton" style={{ paddingTop: '0.5em' }}>
              {changedInfo === null ? (
                <Button variant="contained" disabled type="submit">
                  Nichts geändert
                </Button>
              ) : (
                <Button
                  disabled={props.disabled || !valid}
                  variant="contained"
                  type="submit"
                  onClick={(ev) => {
                    props.onSave(
                      removeUnwantedProperties(cleanInputFromSchema(currentFormData, props.schema)) as IEntity
                    )
                  }}
                >
                  Speichern
                </Button>
              )}
            </div>
          </Form>
          {props.children}
          <p>Daten entsprechen dem Schema {props.schema.title}.</p>
          <section aria-label="diffVal" aria-hidden={true} style={{ display: 'none' }}>
            <span>{JSON.stringify(diffVal)}</span>
          </section>
          <section aria-label="currentFormData" aria-hidden={true} style={{ display: 'none' }}>
            <span>{JSON.stringify(currentFormData)}</span>
          </section>
          {changedInfo}
          <ReactJson
            name="Details"
            collapsed={true}
            displayObjectSize={false}
            src={{
              saveData: removeUnwantedProperties(cleanInputFromSchema(currentFormData, props.schema)),
              entity_property: props.entity,
              currentFormData,
              cleanEntity,
              old: entityWithDefaults,
              new: cleanInputFromSchema(currentFormData, props.schema),
              schema: props.schema,
            }}
          />
        </>
      </Stack>
    </section>
  )
}

/** Die Formulardaten anhand der Schemadaten "aufräumen".
 * Besser wäre es, die GraphQL Definition vom Server
 * zu nehmen, aber da hab ich momentan keinen Ansatz zu.
 */
function cleanInputFromSchema(formData: Record<string, any>, schema: JSONSchema7) {
  const newFormData = { ...formData }

  // TODO: Properties, die nicht im Schema sind, schreiben wir nicht zurück
  const schemaProps = Object.keys(schema.properties as object)
  for (const key of Object.keys(newFormData)) {
    // hier fehlt die Rekursion ...
    if (!schemaProps.includes(key)) {
      delete newFormData[key]
    }
  }
  return cleanGqlInput(removeReadonly(schema, newFormData))
}

/** Gibt die geänderten Daten als Object und ein React-Element mit Erklärung zurück
 * */
function _getDiff(entity: object, currentFormData: object, schema: JSONSchema7) {
  let finalFormData = trimmAll(currentFormData)

  /// rjsf setzt leere Textfelder auf undefined, das reparieren wir hier
  finalFormData = myMerge({}, [jsonEmptyStrings(schema), fixStrings(entity, finalFormData)])
  finalFormData.definitions = undefined
  const diffVal = smartCleanDiff(entity, finalFormData)

  const changed =
    Object.keys(diffVal).length > 0 ? (
      <div>
        <Divider>geänderte Werte</Divider>

        <section aria-label="geänderte Werte">
          <JSONPretty data={diffVal} />
        </section>
      </div>
    ) : null
  return [diffVal, changed]
}

/** trims all Strings within an Object
 * */
export function trimmAll(obj: any) {
  return transform(obj, (result: any, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || (value != null && typeof value === 'object')) {
      value = trimmAll(value)
    }
    // trim strings
    if (typeof value === 'string') {
      value = value.trim()
    }
    result[key] = value
  })
}

/** rjsf setzt leere Textfelder auf undefined, das reparieren wir hier
 * */
export function fixStrings(original: any, newObject: any) {
  return transform(
    { ...original },
    (result, value, key) => {
      // Recurse into arrays and objects.
      if (newObject?.[key] && (Array.isArray(value) || (value != null && typeof value === 'object'))) {
        value = fixStrings(value, newObject[key])
      }
      // fix strings
      if (typeof value === 'string' && !newObject[key]) {
        newObject[key] = ''
      }
      result[key] = newObject[key]
    },
    newObject
  )
}

const smartCleanDiff = (entity: IEntity, finalFormData: IEntity, schema: JSONSchema7) => {
  // this does not work
  const cleanEntity = jsonSchemaDataMerge({}, [jsonEmptyStrings(schema), entity])
  cleanEntity.definitions = undefined

  try {
    const diffVal = cleanDiff(cleanEntity, finalFormData)
    if (cleanEntity.positionen !== undefined || finalFormData.positionen !== undefined) {
      if (finalFormData?.positionen?.length !== cleanEntity?.positionen?.length) {
        diffVal.positionen = finalFormData.positionen
      } else {
        diffVal.positionen = finalFormData.positionen.map((pos, index: number) =>
          cleanDiff(cleanEntity.positionen[index], finalFormData.positionen[index])
        )
      }
    }
    return diffVal
  } catch (e) {
    console.error(e)
    return {}
  }
}

function _customizer(objValue: any, srcValue: any): any {
  if (Array.isArray(srcValue)) {
    return srcValue
  }
  if (isPlainObject(srcValue)) {
    return srcValue
  }
}

export function myMerge(
  destination: Record<string, any>,
  sources: Record<string, any>[]
): Record<string, any> {
  for (const source of sources) {
    destination = mergeWith(destination, source, _customizer)
  }
  return destination
}

function removeUnwantedProperties(obj: any) {
  const newObj = { ...obj }

  for (const key of Object.keys(obj)) {
    if (['id'].includes(key)) {
      delete newObj[key]
    }
    if (key.startsWith('_')) {
      delete newObj[key]
    }
  }

  return newObj
}
