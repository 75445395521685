// Entyities einer "Kind" als Liste darstellen

import { useQuery } from '@apollo/client'
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar'
import { findAllByKey } from '@hudora/hd-ts-util'
import { DocumentNode } from 'graphql'
import reverse from 'lodash.reverse'
import sortBy from 'lodash.sortby'
import React from 'react'

import { IEntity, TListQueryOptions } from '../../types'
import { useAddPanel } from '../hooks/useAddPanel'
import { IEntityTableProps } from './EntityTable'
import { KTTheEntityTable, ktUpdateListQueryOptions } from './KindListeHelpers'

export interface IKindListeProps extends Omit<IEntityTableProps, 'rowData'> {
  listQuery: DocumentNode // GQL-Abfrage, um an die daten zu gelangen
  listQueryOptions?: TListQueryOptions // GQL-Optionen für die List-Query
  createMutation?: DocumentNode
  createTemplate?: Partial<IEntity>
  createPreset?: Record<string, any>
  editMutation?: DocumentNode
  nr?: string // Designator des übergeordneten Objekts
  //  commandBarItems?: Array<ICommandBarItemProps>
  entity?: IEntity // Übergeordnete Entity
  noSort?: boolean // suppress sorting
  className?: string
}
/**
 * Zeigt eine Liste mit Entities an und ermöglicht neue Anzulegen.
 * Das ist die default Seite für zB den "Kunden" oder "Gruppen" Bereich.
 * Steht in sehr engem Zusammenhang mit <SchemaTable>,
 * siehe auch dort die Dokumentation.
 *
 * @deprecated
 *
 */
export const KindListe = (props: IKindListeProps) => {
  const listQueryOptions = ktUpdateListQueryOptions(props)
  const { data, loading, error, networkStatus } = useQuery(props.listQuery, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    ...listQueryOptions,
  })
  const [createItem, createPanel, createButton] = useAddPanel({
    ...props,
    listQueryOptions,
  })

  const items: Array<ICommandBarItemProps> = [createItem]

  let rowData
  if (data?.nodes?.edges) {
    // single level paginated access
    rowData = data?.nodes?.edges.map((x) => x?.node)
  }
  if (!rowData || rowData?.length < 1) {
    // single level non-paginated access
    rowData = data?.entities || data?.entity?.nodes || undefined
  }

  if (!rowData || rowData?.length < 1) {
    // single level non paginated access
    rowData = findAllByKey(data, 'nodes')
  }

  if (!props.noSort) {
    rowData = reverse(sortBy(rowData, ['updated_at', 'designator', 'created_at']))
  }

  return (
    <KTTheEntityTable
      {...props}
      listQueryOptions={listQueryOptions}
      createPanel={createPanel}
      createButton={createButton}
      loading={loading}
      error={error}
      networkStatus={networkStatus}
      commandBarItems={items}
      rowData={rowData}
    ></KTTheEntityTable>
  )
}
