// Anzeige von SKU Mastern
// Created by Dr. Maximillian Dornseif 2020-12-20
// Copyright 2020 HUDORA

import { gql, useMutation } from '@apollo/client'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { Stack } from '@fluentui/react/lib/Stack'
import React, { useCallback } from 'react'

import { SKUM_FRAGMENT, SKUM_PIM_FRAGMENT, SKU_FRAGMENT } from '../fragments'
import { IEntitySubPage } from '../lib/components/EntityPage'
import { EntitySubDisplay } from '../lib/components/EntitySubDisplay'
import { KindPage } from '../lib/components/KindPage'
import { KindLightList } from './KindLightList'

const SUGGESTIONS_QUERY = gql`
  query SkuMasterSuggestionsQuery($term: String!) {
    fulltextSearch: h3SkuMasterSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`

export const SKUM_LIST_QUERY = gql`
  query SkuMasterPagesQuery($cursor: String) {
    nodes: h3SkuMasterPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        # cursor
        node {
          ...skuMasterFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SKUM_FRAGMENT}
`

export const SKUM_QUERY = gql`
  query SkumQuery($nr: ID!) {
    entity: h3SkuMaster(designator: $nr) {
      ...skuMasterFragment
      skuList {
        ...skuFragment
      }
    }
  }
  ${SKUM_FRAGMENT}
  ${SKU_FRAGMENT}
`

export const SKUM_MUTATION = gql`
  mutation h3SkuMasterMutation($designator: ID!, $input: H3SkuMasterInput!) {
    entity: h3SkuMasterUpdate(designator: $designator, input: $input) {
      ...skuMasterFragment
    }
  }
  ${SKUM_FRAGMENT}
`

const PIM_QUERY = gql`
  query skumPimLesenQuery($nr: ID!) {
    h3SkuMaster(designator: $nr) {
      id
      designator
      entity: pim {
        ...skuMasterInfoFragment
      }
    }
  }
  ${SKUM_PIM_FRAGMENT}
`

const PIM_MUTATION = gql`
  mutation MasterPimAendern($designator: ID!, $input: H3SkuMasterInfoInput!) {
    entity: h3SkuMasterInfoUpdate(designator: $designator, input: $input) {
      ...skuMasterInfoFragment
    }
  }
  ${SKUM_PIM_FRAGMENT}
`

export const SkuMastersApp = () => {
  const SUB_PAGES: IEntitySubPage[] = [
    {
      title: 'SMIM (PIM)',
      path: 'pim',
      component: (
        <EntitySubDisplay
          key="PIM"
          nr="?"
          schemaName="H3SkuMasterInfo"
          entityQuery={PIM_QUERY}
          editMutation={PIM_MUTATION}
        />
      ),
    },
    {
      title: 'Neue SKU Version',
      path: 'version',
      component: <NeueVersionAnlegen entity={null} />,
      // iconName: 'Add',
    },
  ]

  return (
    <div>
      <p>
        Ein SKU-Master ist, was wir tatsächlich physisch liefern können. Z.B. "Gartentrampolin Karton 1 von
        5". Aber auch "BigWheel 205, grün schwarz".
      </p>
      <KindPage
        basePath={'/eap'}
        componentPath={'mastersku'}
        schemaName={'H3SkuMaster'}
        title={'SKU Master (Wareneinheiten)'}
        listQuery={SKUM_LIST_QUERY}
        entityQuery={SKUM_QUERY}
        editMutation={SKUM_MUTATION}
        subPages={SUB_PAGES}
        suggestionQuery={SUGGESTIONS_QUERY}
      >
        <DisplaySkus key="DisplaySkus" entity={{}} schemaName="H3Sku" />
      </KindPage>
    </div>
  )
}

function DisplaySkus({ entity, schemaName }) {
  return (
    <section>
      <h3>Zugehörige SKUs</h3>
      <KindLightList
        entities={entity?.skuList.filter(Boolean) || []}
        schemaName={schemaName}
        hideExcept={[
          'designator',
          'dimEx',
          'mngEx',
          'gwEx',
          'gwPal',
          'keinEx',
          'mngPal',
          'hPal',
          'xlPal',
          'zusatz',
          'mng20ft',
          'mng40ft',
          'mng40fthq',
          'ist_aktiv',
          'kunde',
          'updated_at',
          'created_at',
        ]}
      />
    </section>
  )
}

const NEWVERSION_MUTATION = gql`
  mutation NewVersion($nr: ID!) {
    h3SkuMasterAddVersion(designator: $nr) {
      ...skuMasterFragment
      skuList {
        ...skuFragment
      }
    }
  }
  ${SKUM_FRAGMENT}
  ${SKU_FRAGMENT}
`
export const NeueVersionAnlegen = (props: { entity: Record<string, any> }) => {
  const [uebertragen, { loading: saving }] = useMutation(NEWVERSION_MUTATION, {
    variables: { nr: props.entity.designator },
  })
  const anlegenStarten = useCallback((ev: any) => uebertragen(), [uebertragen])

  return (
    <Stack tokens={{ childrenGap: '1em' }}>
      <div>Hier können sie zur Master-SKU {props.entity.designator} eine neue Version (SKU) anlegen.</div>
      <div>
        <PrimaryButton disabled={saving} text="SKU zufügen" onClick={anlegenStarten}>
          {saving ? <Spinner labelPosition="right" size={SpinnerSize.small} /> : null}
        </PrimaryButton>
      </div>
      <div>
        Bisher gibt es folgende Versionen:
        <DisplaySkus entity={props.entity} schemaName="H3Sku" />
      </div>
    </Stack>
  )
}
