/* eslint-disable dot-notation */
// Sub Entieties etc. in Tabs anzeigen.
// Wrapper für EntityDisplayInner mit Nachlade-Funktionalität.
// Created by Dr. Maximillian Dornseif 2020-10
// Copyright 2020 Dr. Maximillian Dornseif

import { useQuery } from '@apollo/client'
import { Stack } from '@fluentui/react/lib/Stack'
import { DocumentNode } from 'graphql'
import React from 'react'

import { IEntity } from '../../types'
import { getValueDeep } from '../util/get-value-deep'
import { EntityPageInner } from './EntityPageInner'
import { LadeChecker } from './UiChecker'

export interface EntitySubDisplayProps {
  nr: string // Designator
  entityQuery: DocumentNode
  schemaName: string
  editMutation?: DocumentNode // bearbeiten einer SubEntitiy
  forDesignator?: string // zu welchem Entity soll das SubEntity erzeugt werden?
}

/** Anzeige für  untergeordnete "Entities", wis zB Lieferadressen etc.
 * Führt selber die Query aus. Sie Query muss ein `entity` haben.
 * Wenn es sich um ein SubEntity, wie EDI-Daten zu einem Kunden,
 * Oder den Versandanweisungen zu einem Kunden handelt, kann man
 * diese auch neu zufügen, wenn createMutation übrgeben wird
 *
 * @deprecated
 */
export const EntitySubDisplay = (props: EntitySubDisplayProps) => {
  const { loading, error, data } = useQuery(props.entityQuery, {
    variables: { nr: props.nr },
    skip: !props.nr,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    pollInterval: 5 * 1000 * 60,
  })

  if (!props.nr) {
    return null
  }

  const entity = getValueDeep<IEntity>('entity', data)

  if (error) {
    return <LadeChecker loading={loading} error={error} label={`Lade Daten für ${props.nr} …`} />
  }

  const noData = !entity ? (
    <Stack.Item align="center">
      <Stack verticalAlign="center">{`Keine Daten für "${props.nr}" erhalten`}</Stack>
    </Stack.Item>
  ) : null

  return (
    <div data-testid="EntitySubDisplay">
      <LadeChecker loading={loading} error={error} label={`Lade Daten für ${props.nr} …`} />
      <Stack tokens={{ childrenGap: '0.2em' }} grow>
        {noData || (
          <EntityPageInner entity={entity} schemaName={props.schemaName} mutation={props.editMutation} />
        )}
      </Stack>
    </div>
  )
}
