import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

export interface IHdSpinnerProps {
  children?: React.ReactNode
}

export function HdSpinner(props: IHdSpinnerProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <CircularProgress color="inherit" size={20} />
      </Box>
      <Box sx={{ m: 1, position: 'relative' }}>{props.children ? props.children : 'Lade…'}</Box>
    </Box>
  )
}
