// Anzeige von SKU Mastern
// Created by Dr. Maximillian Dornseif 2020-12-20
// Copyright 2020 HUDORA
import gql from 'graphql-tag'

import { KindLightList } from '../eAP/KindLightList'
import {
  BESTANDSBUCHSUMME_FRAGMENT,
  BESTANDSBUCH_FRAGMENT,
  SKUM_FRAGMENT,
  SKU_FRAGMENT,
  ZUTEILUNG_FRAGMENT,
} from '../fragments'
import { IEntitySubPage } from '../lib/components/EntityPage'
import { KindListe } from '../lib/components/KindListe'
import { KindPage } from '../lib/components/KindPage'
import { BESTELL_LIST_QUERY } from './IcSkusApp'

export const IcSkuMastersApp = () => {
  const SUB_PAGES: IEntitySubPage[] = [
    {
      title: 'Bestände',
      path: 'bestand',
      component: (
        <KindListe
          className="authEnforced"
          schemaName={'HwIcBestandsbuchSumme'}
          listQuery={BESTAND_LIST_QUERY}
          title={''}
        />
      ),
    },
    {
      title: 'Bestellungen',
      path: 'bestellungen',
      component: <KindListe schemaName={'HwBestellPosition'} listQuery={BESTELL_LIST_QUERY} title={''} />,
    },
    {
      title: 'Zuteilungen',
      path: 'zuteilungen',
      component: <KindListe schemaName={'HwIcZuteilung'} listQuery={ZUTEILUNG_LIST_QUERY} title={''} />,
    },
    {
      title: 'Bestandsbuch',
      path: 'bestandsbuch',
      component: (
        <KindListe
          className="authEnforced"
          schemaName={'HwIcBestandsbuchEintrag'}
          listQuery={BESTANDSBUCH_LIST_QUERY}
          title={''}
        />
      ),
    },
  ]

  return (
    <KindPage
      basePath={'/ic'}
      componentPath={'mastersku'}
      schemaName={'H3SkuMaster'}
      title={'SKU Master (Wareneinheiten)'}
      listQuery={SKUM_LIST_QUERY}
      entityQuery={SKUM_QUERY}
      subPages={SUB_PAGES}
      suggestionQuery={SKUM_SUGGESTIONS_QUERY}
    >
      <DisplaySkus key="DisplaySkus" entity={{}} schemaName="H3Sku" />
    </KindPage>
  )
}

function DisplaySkus({ entity, schemaName }) {
  return (
    <section>
      <h3>Zugehörige SKUs</h3>
      <KindLightList
        entities={entity?.skuList || []}
        schemaName={schemaName}
        hideExcept={[
          'designator',
          'dimEx',
          'mngEx',
          'gwEx',
          'gwPal',
          'keinEx',
          'mngPal',
          'hPal',
          'xlPal',
          'zusatz',
          'mng20ft',
          'mng40ft',
          'mng40fthq',
          'ist_aktiv',
          'kunde',
          'updated_at',
          'created_at',
        ]}
      />
    </section>
  )
}

const SKUM_SUGGESTIONS_QUERY = gql`
  query SuggestMasterQuery($term: String!) {
    fulltextSearch: h3SkuMasterSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`

export const SKUM_LIST_QUERY = gql`
  query SkuMasterPagesMaybeInaktiv($cursor: String, $auchInaktive: Boolean) {
    nodes: h3SkuMasterPages(
      after: $cursor
      auchInaktive: $auchInaktive
      filter: { ist_aktiv: { eq: true } }
    ) {
      edges {
        # cursor
        node {
          ...skuMasterFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SKUM_FRAGMENT}
`

export const SKUM_QUERY = gql`
  query SkuMasterWithSkusQuery($nr: ID!) {
    entity: h3SkuMaster(designator: $nr) {
      ...skuMasterFragment
      skuList {
        ...skuFragment
      }
    }
  }
  ${SKUM_FRAGMENT}
  ${SKU_FRAGMENT}
`

export const ZUTEILUNG_LIST_QUERY = gql`
  query PZMasterQuery($nr: ID!) {
    entity: h3SkuMaster(designator: $nr) {
      id
      nodes: icZuteilungList {
        ...zuteilungFragment
      }
    }
  }
  ${ZUTEILUNG_FRAGMENT}
`

export const BESTAND_LIST_QUERY = gql`
  query PBestMasterQuery($nr: ID!) {
    entity: h3SkuMaster(designator: $nr) {
      id
      skuList {
        nodes: bestaende {
          ...bestandsbuchsummeFragment
        }
      }
    }
  }
  ${BESTANDSBUCHSUMME_FRAGMENT}
`
export const BESTANDSBUCH_LIST_QUERY = gql`
  query PBuchQuery($nr: ID!) {
    entity: h3SkuMaster(designator: $nr) {
      id
      nodes: icBestandsbuchEintragList {
        ...bestandsbuchFragment
      }
    }
  }
  ${BESTANDSBUCH_FRAGMENT}
`
