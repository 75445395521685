// Anzeige von Produkten
// Created by Dr. Maximillian Dornseif 2020
// Copyright 2020, 2021 HUDORA

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import ReactJson from 'react-json-view';
import { navigate } from 'wouter/use-location';

import {
  ITEM_FRAGMENT,
  ITEM_PIM_FRAGMENT,
  PIM_FRAGMENT,
  PRODUKT_FRAGMENT,
  SKUM_FRAGMENT,
  SKUM_PIM_FRAGMENT,
  SKUSUM_FRAGMENT,
  SKU_FRAGMENT,
  SKU_PIM_FRAGMENT,
} from '../fragments';
import { IEntitySubPage } from '../lib/components/EntityPage';
import { EntitySubDisplay } from '../lib/components/EntitySubDisplay';
import { KindPage } from '../lib/components/KindPage';
import { LadeChecker } from '../lib/components/UiChecker';
import { IEntity } from '../types';
import { NeuesItemAnlegen } from './EapProdukteAnlegen';
import { KindLightList } from './KindLightList';

const SUGGESTIONS_QUERY = gql`
  query SkuProduktSearch2Query($term: String!) {
    fulltextSearch: h3ProduktSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`;

export const PRODUKT_LIST_QUERY = gql`
  query ProduktList($cursor: String) {
    nodes: h3ProduktPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        cursor
        node {
          ...produktFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PRODUKT_FRAGMENT}
`;

export const PRODUKT_QUERY = gql`
  query produktLesenQuery($nr: ID!) {
    entity: h3Produkt(designator: $nr) {
      ...produktFragment
    }
  }
  ${PRODUKT_FRAGMENT}
`;
export const PRODUKT_WITH_ITEM_QUERY = gql`
  query produktUswLesen($nr: ID!) {
    entity: h3Produkt(designator: $nr) {
      ...produktFragment
      itemList {
        ...itemFragment
      }
    }
  }
  ${PRODUKT_FRAGMENT}
  ${ITEM_FRAGMENT}
`;

const PRODUKT_MUTATION = gql`
  mutation ProduktAendernMutation($designator: ID!, $input: H3ProduktInput!) {
    entity: h3ProduktUpdate(designator: $designator, input: $input) {
      ...produktFragment
    }
  }
  ${PRODUKT_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation ProduktAnlegenMutation($input: H3ProduktInput!) {
    entity: h3ProduktCreate(input: $input) {
      ...produktFragment
    }
  }
  ${PRODUKT_FRAGMENT}
`;

const PIM_QUERY = gql`
  query produktPimLesen($nr: ID!) {
    h3Produkt(designator: $nr) {
      id
      designator
      entity: pim {
        ...produktInfoFragment
      }
    }
  }
  ${PIM_FRAGMENT}
`;

const PIM_MUTATION = gql`
  mutation ProduktPimAendern($designator: ID!, $input: H3ProduktInfoInput!) {
    entity: h3ProduktInfoUpdate(designator: $designator, input: $input) {
      ...produktInfoFragment
    }
  }
  ${PIM_FRAGMENT}
`;

const SUB_PAGES: IEntitySubPage[] = [
  {
    title: 'PIM',
    path: 'pim',
    component: (
      <EntitySubDisplay
        key="PIM"
        nr={null}
        schemaName="H3ProduktInfo"
        entityQuery={PIM_QUERY}
        editMutation={PIM_MUTATION}
      />
    ),
  },
  {
    title: 'Produktdaten',
    path: 'data',
    component: <FullProduct nr={null} />,
  },
  {
    title: 'Neues Item',
    path: 'addItem',
    component: <NeuesItemAnlegen entity={null} />,
    // iconName: 'Add',
  },
];
export const ProdukteApp = () => {
  return (
    <div>
      <p>
        Produkte sind Objekte, die so im Katalog stehen und einen Preis haben. Z.B. Inliner Kids in
        verschiedenen Farben und Größen. Produkte haben ein oder mehrere zugehörige Items.
      </p>
      <KindPage
        basePath={'/eap'}
        componentPath={'prod'}
        initialLimit={1000}
        schemaName="H3Produkt"
        title={'Produkte'}
        subPages={SUB_PAGES}
        listQuery={PRODUKT_LIST_QUERY}
        entityQuery={PRODUKT_QUERY}
        editMutation={PRODUKT_MUTATION}
        createMutation={CREATE_MUTATION}
        suggestionQuery={SUGGESTIONS_QUERY}
        entityCommandBarItems={[
          {
            key: 'warenwirtschaft',
            text: 'in der Warenwirtschaft',
            ariaLabel: 'Produkt-Warenwirtschaft',
            iconProps: {
              iconName: 'Packages',
            },
            iconOnly: false,
            onClick: (e) => {
              navigate(window.location.pathname.replace('/eap/prod/', '/wawi/prod/'));
            },
          },
        ]}
      >
        <DisplayItems entity={null} />
      </KindPage>
    </div>
  );
};
export const DisplayItems = (props: { entity: any }) => {
  const { loading, data, error } = useQuery(PRODUKT_WITH_ITEM_QUERY, {
    variables: { nr: props?.entity?.designator },
  });
  const entity = data?.entity;
  if (loading || error) {
    return <LadeChecker loading={loading} error={error} />;
  }

  const entities: Array<IEntity> = entity?.itemList as Array<IEntity>;

  return (
    <div>
      <h3>Zugehörige Items</h3>
      <KindLightList
        entities={entities}
        schemaName={'H3Item'}
        hideExcept={[
          'designator',
          'zusatz',
          'komponenten',
          'ean',
          'ist_aktiv',
          'kunde',
          'updated_at',
          'created_at',
        ]}
      />
    </div>
  );
};

function FullProduct(props) {
  const { loading, error, data } = useQuery(FULL_PRODUKT_QUERY, {
    variables: { nr: props.nr },
    skip: !props.nr,
  });
  return (
    <div>
      <h2>Komplette Produktdaten bis hin zur SKU</h2>
      <LadeChecker loading={loading} error={error} label={`Lade Beleg ${props.nr}`}>
        <ReactJson
          src={data?.entity}
          name="Produktdaten"
          collapsed={4}
          collapseStringsAfterLength={100}
          displayDataTypes={false}
          iconStyle="square"
        />
      </LadeChecker>
    </div>
  );
}

export const FULL_PRODUKT_QUERY = gql`
  query produktDetailsLesenQuery($nr: ID!) {
    entity: h3Produkt(designator: $nr) {
      ...produktFragment
      pim {
        ...produktInfoFragment
      }
      itemList {
        imageUrl
        ...itemFragment
        pim {
          ...itemInfoFragment
        }
        skuMasterList {
          ...skuMasterFragment
          pim {
            ...skuMasterInfoFragment
          }
          skuNeuste {
            ...skuFragment
            pim {
              ...skuInfoFragment
            }
          }
          skuList {
            ...skuFragment
            pim {
              ...skuInfoFragment
            }
          }
        }
        skuSum {
          ...skuSumFragment
        }
      }
    }
  }

  ${PRODUKT_FRAGMENT}
  ${PIM_FRAGMENT}
  ${ITEM_FRAGMENT}
  ${ITEM_PIM_FRAGMENT}
  ${SKUM_FRAGMENT}
  ${SKUM_PIM_FRAGMENT}
  ${SKU_FRAGMENT}
  ${SKU_PIM_FRAGMENT}
  ${SKUSUM_FRAGMENT}
`;
