// Anzeige von Items (Artikel) für den Artikelpass
// Created by Dr. Maximillian Dornseif 2020
// Copyright 2020-2023 HUDORA

import { Dialog } from '@fluentui/react'
import { LinkRender } from '@hudora/hd-link'
import { LadeChecker } from '@hudora/hd-react-components'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useDialog } from 'fluentui-hooks'
import ReactJson from 'react-json-view'
import { useQuery } from 'urql'
import { navigate } from 'wouter/use-location'

import { PdfDialogInner } from '../components/PdfDialog'
import { FULL_ITEM_QUERY, ITEM_LIST_QUERY } from '../eAP/EapItemsApp'
import { EntityCard } from '../lib/components/EntityCard'
import { EntityRender } from '../lib/components/EntityRender'
import { IEntitySubPage3 } from '../lib/components/ke/EntityPage3'
import { KindListe3 } from '../lib/components/ke/KindListe3'
import { KindPage3 } from '../lib/components/ke/KindPage3'
import {
  ARTIKELKONTO_QUERY,
  AUFTRAG_LIST_QUERY,
  BESTAND_LIST_QUERY,
  BESTELL_LIST_QUERY,
  ITEM_QUERY,
  ITEM_WITH_MASTER_AND_LEGACY_QUERY,
  PIM_QUERY,
  RESERVIERUNG_LIST_QUERY,
  SUGGESTIONS_QUERY,
  ZUTEILUNG_LIST_QUERY,
} from './WaWiItemsAppQueries'
import { DisplayProduktPreiseUndVerfuegbarkeiten } from './WaWiProdukteApp'

export const WaWiItemsApp = () => {
  const [openPdfDialog, pdfDialogProps, , dismissDialog] = useDialog(
    'Angebotsblatt rendern',
    'Bitte wählen Sie ein passendes Template und Ausgabeformat.'
  )

  return (
    <div>
      <KindPage3
        basePath={'/wawi'}
        componentPath={'item'}
        initialLimit={1000}
        schemaName={'H3Item'}
        hideExcept={['ist_aktiv', 'designator', 'name', 'ean', 'forDesignator', 'mehrkarton']}
        title={'Items (Artikel)'}
        subPages={SUBPAGES}
        listQuery={ITEM_LIST_QUERY}
        entityQuery={ITEM_QUERY}
        suggestionQuery={SUGGESTIONS_QUERY}
        entityCommandBarItems={[
          {
            key: 'eap',
            text: 'im eAP',
            ariaLabel: 'Item-eAP',
            iconProps: {
              iconName: 'ProductList',
            },
            iconOnly: false,
            onClick: (e) => {
              navigate(window.location.pathname.replace('/wawi/item/', '/eap/item/'))
            },
          },
          {
            key: 'pdfrender',
            text: 'Angebotsblatt rendern',
            ariaLabel: 'BAngebotsblatt rendern',
            iconProps: { iconName: 'PenWorkspace' },
            onClick: openPdfDialog,
          },
        ]}
        renderEntityPageRightSidebar={(e) => (
          <Box mb={2}>
            <PimCard nr={e?.designator} />
          </Box>
        )}
        renderEntityPageBottom={(e) => (
          <DisplayItemDetails
            /* Entity wird durch die KindPage beim Rendern gesetzt, dies ist ein Dummy */
            entity={e}
            nr={e.designator}
            dismissDialog={dismissDialog}
            pdfDialogProps={pdfDialogProps}
          />
        )}
      ></KindPage3>
    </div>
  )
}

function DisplayItemDetails(props) {
  const [{ data }] = useQuery({ query: FULL_ITEM_QUERY, variables: { nr: props.nr }, skip: !props.nr })
  return (
    <>
      <DisplayProduktPreiseUndVerfuegbarkeiten
        entity={{ designator: props.entity.forDesignator }}
        highlight={props.entity.designator}
      />
      <DisplaySkus entity={props.entity} nr={props.nr} />
      <Dialog {...props.pdfDialogProps}>
        <PdfDialogInner
          data={{ ...data?.entity, belegart: 'AngebotsblattItem' }}
          onDismiss={props.dismissDialog}
        ></PdfDialogInner>
      </Dialog>
    </>
  )
}
function DisplaySkus(props) {
  const [{ fetching: loading, data, error }] = useQuery({
    query: ITEM_WITH_MASTER_AND_LEGACY_QUERY,
    variables: { nr: props.nr },
  })
  const entity = data?.entity

  return (
    <LadeChecker loading={loading} error={error}>
      <>
        <hr />
        <DisplayImage entity={entity} />
        Am Lager als:
        <Stack direction="row" spacing={2}>
          {entity?.skuMasterList?.map((master) => (
            <div key={master.designator}>
              <div>
                {master.zusatz} {master.eanEx}{' '}
                <b>
                  <LinkRender value={master.designator} />
                </b>
              </div>
              {master?.skuList?.map((sku) => (
                <div key={sku.designator}>
                  {sku.ist_aktiv ? (
                    <EntityRender entity={sku} schemaName="H3Sku" />
                  ) : (
                    <h3>
                      <LinkRender value={sku.designator} />
                    </h3>
                  )}
                </div>
              ))}
            </div>
          ))}
        </Stack>
        <ReactJson name="Details" collapsed={true} displayObjectSize={false} src={{ entity }} />
      </>
    </LadeChecker>
  )
}

function DisplayImage(props) {
  const layout = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    backgroundImage: `url(${props.entity.imageUrl})`,
    backgroundSize: 'contain',
    width: '15em',
    height: '15em',
    position: 'absolute' as any,
    right: 0,
  }
  return props.entity.imageUrl ? <div style={layout}></div> : null
}

const SUBPAGES: IEntitySubPage3[] = [
  {
    title: 'Artikelkonto',
    path: 'k',
    render: (entity) => (
      <KindListe3
        title={'Artikelkonto'}
        schemaName={'HwArtikelKonto'}
        listQuery={ARTIKELKONTO_QUERY}
        listQueryVars={{ nr: entity.designator }}
        hideExcept={[
          'datum',
          'bewegung',
          'vorgangsnr',
          'partnernr',
          'partnerName',
          'bestand',
          'freie_menge',
          'typ',
          'designator',
          'verspaetet',
        ]}
        noSort={true}
      />
    ),
  },
  {
    title: 'Aufträge',
    path: 'a',
    render: (entity) => (
      <KindListe3
        title={'offene Aufträge'}
        schemaName={'HwAuftragsPosition'}
        listQuery={AUFTRAG_LIST_QUERY}
        hideExcept={[
          'auftragsnr',
          'empfaengernr',
          'kunde.name',
          'menge_offen',
          'menge_geliefert',
          'menge_bestellt',
          'preis',
          'preisherkunft',
          'erledigt',
          'created_at',
          'updated_at',
        ]}
        listQueryVars={{ nr: entity.designator }}
      />
    ),
  },
  {
    title: 'Blockaufträge',
    path: 'r',
    render: (entity) => (
      <KindListe3
        title={'offene Blockaufträge'}
        schemaName={'HwReservierungsPosition'}
        listQuery={RESERVIERUNG_LIST_QUERY}
        listQueryVars={{ nr: entity.designator }}
      />
    ),
  },
  {
    title: 'Bestellungen',
    path: 'bestellungen',
    render: (entity) => (
      <KindListe3
        schemaName={'HwBestellPosition'}
        listQuery={BESTELL_LIST_QUERY}
        listQueryVars={{ nr: entity.designator }}
      />
    ),
  },
  {
    title: 'Bestände',
    path: 'bestand',
    render: (entity) => (
      <KindListe3
        className="authEnforced"
        schemaName={'HwIcBestandsbuchSumme'}
        listQuery={BESTAND_LIST_QUERY}
        listQueryVars={{ nr: entity.designator }}
        hideExcept={[
          'lager',
          'sku',
          'lager_menge',
          'lager_wert',
          'adw',
          'zugangsmenge',
          'abgangsmenge',
          'bewegungen',
          'erster_zugang',
          'letzter_abgang',
          'gebucht_bis_ts',
        ]}
      />
    ),
  },
  {
    title: 'Zuteilungen',
    path: 'zuteilungen',
    render: (entity) => (
      <KindListe3
        schemaName={'HwIcZuteilung'}
        listQuery={ZUTEILUNG_LIST_QUERY}
        listQueryVars={{ nr: entity.designator }}
      />
    ),
  },
]

export function PimCard(props) {
  const [result] = useQuery({
    query: PIM_QUERY,
    variables: { nr: props.nr },
  })
  const { data, fetching, error } = result
  const sx = { fontSize: 12 }

  if (!props.nr) {
    return null
  }

  return (
    <LadeChecker loading={fetching} error={error} data={data} label={`Lade PIM`}>
      <EntityCard entity={data?.h3Item?.entity} schemaName={'H3ItemInfo'} title="PIM" />
    </LadeChecker>
  )
}
