// Anzeige von SKU Daten im eAP
// Created by Dr. Maximillian Dornseif 2021
// Copyright 2020-2022 HUDORA
import gql from 'graphql-tag';
import React from 'react';
import { navigate } from 'wouter/use-location';

import { SKU_FRAGMENT, SKU_PIM_FRAGMENT } from '../fragments';
import { IEntitySubPage } from '../lib/components/EntityPage';
import { EntitySubDisplay } from '../lib/components/EntitySubDisplay';
import { KindPage } from '../lib/components/KindPage';

export const SkusApp = () => {
  const subPages: IEntitySubPage[] = [
    {
      title: 'SIM (PIM)',
      path: 'pim',
      component: (
        <EntitySubDisplay
          key="PIM"
          nr="?"
          schemaName="H3SkuInfo"
          entityQuery={PIM_QUERY}
          editMutation={PIM_MUTATION}
        />
      ),
    },
  ];

  return (
    <div>
      <p>
        SKUs sind die aktuellen Objekte, die wir am Lager stehen haben: <b>S</b>
        tock <b>k</b>eeping <b>u</b>nits. Z.B. "Karton 1 von 5 Version 3 von Gartentrampolin".
      </p>
      <KindPage
        basePath={'/eap'}
        componentPath={'sku'}
        schemaName={'H3Sku'}
        title={'SKUs (Lagerinhalt)'}
        subPages={subPages}
        listQuery={SKU_LIST_QUERY}
        entityQuery={ENTITY_QUERY}
        editMutation={SKU_MUTATION}
        suggestionQuery={SUGGESTIONS_QUERY}
        entityCommandBarItems={[
          {
            key: 'ic',
            text: 'im Lager',
            ariaLabel: 'SKU-IC',
            iconProps: {
              iconName: 'ProductList',
            },
            iconOnly: false,
            onClick: (e) => {
              navigate(window.location.pathname.replace('/eap/sku/', '/ic/sku/'));
            },
          },
        ]}
      />
    </div>
  );
};

const SUGGESTIONS_QUERY = gql`
  query SkuSearchQuery($term: String!) {
    fulltextSearch: h3SkuSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`;

export const SKU_LIST_QUERY = gql`
  query SkuListQuery($cursor: String) {
    nodes: h3SkuPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        cursor
        node {
          ...skuFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SKU_FRAGMENT}
`;

export const ENTITY_QUERY = gql`
  query skuLesenQuery($nr: ID!) {
    entity: h3Sku(designator: $nr) {
      ...skuFragment
    }
  }
  ${SKU_FRAGMENT}
`;

export const SKU_MUTATION = gql`
  mutation h3SkuMutation($designator: ID!, $input: H3SkuInput!) {
    entity: h3SkuUpdate(designator: $designator, input: $input) {
      ...skuFragment
    }
  }
  ${SKU_FRAGMENT}
`;

const PIM_QUERY = gql`
  query skuPimLesenQuery($nr: ID!) {
    h3Sku(designator: $nr) {
      id
      designator
      entity: pim {
        ...skuInfoFragment
      }
    }
  }
  ${SKU_PIM_FRAGMENT}
`;

const PIM_MUTATION = gql`
  mutation SkuPimAendernMutation($designator: ID!, $input: H3SkuInfoInput!) {
    entity: h3SkuInfoUpdate(designator: $designator, input: $input) {
      ...skuInfoFragment
    }
  }
  ${SKU_PIM_FRAGMENT}
`;
