// Rechnungskorrektur / Gutschrift erstellen
// Copyright 2021 HUDORA

import { gql, useMutation } from '@apollo/client'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react/lib/ComboBox'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { TextField } from '@fluentui/react/lib/TextField'
import { LinkRender } from '@hudora/hd-link'
import { ValueRender } from '@hudora/react-jsonschema-valuerender'
import * as Sentry from '@sentry/react'
import cleanDeep from 'clean-deep'
import { DateTime } from 'luxon'
import React, { useState } from 'react'

import { getErrorMessageBar } from '../lib/components/UiChecker'

const CREATE_MUTATION = gql`
  mutation CreateRechnungskorrekturMutation($input: String!) {
    createRechnungskorrekturEntwurf(input: $input)
  }
`

const INITIAL_OPTIONS: IComboBoxOption[] = [
  { key: 'Preisdifferenz', text: 'Preisdifferenz' },
  { key: 'Annahme Verweigerung', text: 'Annahme Verweigerung' },
  { key: 'nicht angetroffen', text: 'nicht angetroffen' },
  { key: 'Storno auf Kundenwunsch', text: 'Storno auf Kundenwunsch' },
  { key: 'Falschlieferung, Retoure', text: 'Falschlieferung, Retoure' },
  { key: 'Fehlmenge', text: 'Fehlmenge' },
  { key: 'Transportschaden', text: 'Transportschaden' },
  { key: 'Reklamation', text: 'Reklamation' },
  { key: 'Gewährleistung', text: 'Gewährleistung' },
  { key: 'Kulanz', text: 'Kulanz' },
]

let newKey = 1

export function RechungskorrekturErstellen({ beleg, onClose, nullmengen }) {
  const [messageBar, setMessageBar] = useState(null)
  const [neueLeistungen, setNeueLeistungen] = React.useState(
    Object.fromEntries(
      beleg.leistungen.positionen.map((pos) => {
        return _baueState(pos, nullmengen)
      })
    )
  )
  const debitNote = {
    einzelpreis: 0,
    itemnr: 'QX500',
    name: 'Debit Note',
    menge: 1,
    posnr_auftrag: 10000,
    faktura: { steuersatz_prozent: '19' },
  }
  const zusatzleistungen = [...beleg.zusatzleistungen.positionen, debitNote]
  const [neueZusatzleistungen, setNeueZusatzleistungen] = React.useState(
    Object.fromEntries(
      zusatzleistungen.map((pos) => {
        return _baueState(pos, nullmengen)
      })
    )
  )

  const [bezugIntern, setBezugIntern] = React.useState('')
  const [bezugKunde, setBezugKunde] = React.useState(beleg.belegnr_kunde)
  const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>('C')
  const [options, setOptions] = React.useState(INITIAL_OPTIONS)
  const onComboBoxChange = React.useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
      let key = option?.key
      if (!option && value) {
        key = `${newKey++}`
        setOptions((prevOptions) => [...prevOptions, { key: key!, text: value }])
      }
      setSelectedKey(key)
    },
    []
  )
  const sum = (array) => array.reduce((pv, cv) => pv + (cv || 0), 0)
  const sumElement = (array, name) => sum(Object.values(array).map((x: any) => x[name]))
  const nettoBetrag =
    sumElement(neueLeistungen, 'nettoBetrag') + sumElement(neueZusatzleistungen, 'nettoBetrag')
  const bruttoBetrag =
    sumElement(neueLeistungen, 'bruttoBetrag') + sumElement(neueZusatzleistungen, 'bruttoBetrag')

  const entwurf = {
    leistungen: [],
    zusatzleistungen: [],
    ...cleanDeep(
      {
        designator: 'RK00000',
        belegdatum: DateTime.now().toISO(),
        kundennr: beleg.kundennr,
        // empfaenger: beleg.empfaenger,
        bezug: {
          rechnung: { nr: beleg.designator, datum: beleg.belegdatum },
          kunde: { nr: bezugKunde || undefined },
          intern: { nr: bezugIntern || undefined },
        },
        infotext_kunde: options.filter((x) => x.key === selectedKey)[0]?.text,
        leistungen: Object.entries(neueLeistungen)
          .map(([k, v]) => {
            return {
              menge: v.menge,
              einzelpreis: v.einzelpreis,
              itemnr: v.itemnr,
              preis_herkunft: 'manuelle korrektur',
              infotext_kunde: v.infotext_kunde,
              bezug: {
                auftragsnr: v?.auftragsnr,
                posnr_auftrag: v.posnr_auftrag,
                kommiauftragsnr: v?.kommiauftragsnr,
                posnr_kommiauftag: v?.posnr_kommiauftag,
                lieferscheinnr: v?.lieferscheinnr,
                posnr_lieferschein: v?.posnr_lieferschein,
                rechnungsnr: v?.rechnungsnr,
                posnr_rechnung: v?.posnr_rechnung,
              },
            }
          })
          .filter((x) => x.menge !== 0 && x.einzelpreis !== 0),
        zusatzleistungen: Object.entries(neueZusatzleistungen)
          .map(([k, v]) => {
            const v2: any = v
            return {
              menge: v2.menge,
              einzelpreis: v2.einzelpreis,
              itemnr: v2.itemnr,
              preis_herkunft: 'manuelle korrektur',
              infotext_kunde: v2.infotext_kunde,
              bezug: {
                auftragsnr: v2?.auftragsnr,
                posnr_auftrag: v2.posnr_auftrag,
                kommiauftragsnr: v2?.kommiauftragsnr,
                posnr_kommiauftag: v2?.posnr_kommiauftag,
                lieferscheinnr: v2?.lieferscheinnr,
                posnr_lieferschein: v2?.posnr_lieferschein,
                rechnungsnr: v2?.rechnungsnr,
                posnr_rechnung: v2?.posnr_rechnung,
              },
            }
          })
          .filter((x) => x.menge !== 0 && x.einzelpreis !== 0),
      },
      { emptyStrings: false }
    ),
  }

  const [mutateEntity, { loading: saving, called }] = useMutation(CREATE_MUTATION, {
    errorPolicy: 'all',
    onCompleted: (x) => {
      console.log(x)
    },
    onError: (e) => {
      Sentry.captureException(e)
      setMessageBar(getErrorMessageBar(e))
    },
  })

  // Daten per GraphQL Mutation an den Server senden.
  const uebertragen = async () => {
    const variables = {
      input: JSON.stringify(entwurf),
    }
    const result = await mutateEntity({ variables })
    // TODO: result verwenden
    if (result?.errors?.map) {
      setMessageBar(getErrorMessageBar(result))
    } else if (result?.errors) {
      setMessageBar(getErrorMessageBar(result.errors))
    } else if (result === undefined) {
      setMessageBar(
        getErrorMessageBar('Schwieriger Server Fehler - vermutlich ist das Ding nicht erreichbar.')
      )
    } else {
      const nr = result.data.createRechnungskorrekturEntwurf
      const bar = (
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true} truncated>
          Der Datensatz wurde erfolgreich gespeichert: <code>{nr}</code>
        </MessageBar>
      )
      setMessageBar(bar)
      onClose(bar)
    }
  }

  return (
    <div>
      {messageBar}
      <h3>Rechnungskorrektur</h3>
      <div>
        Zu Rechnung <LinkRender value={beleg.designator} />/
        <code>{beleg.bezug?.auftrag?.auftragsnr_kunde}</code> vom {beleg.datum}
      </div>
      <div>
        {beleg.kunde?.name} <LinkRender value={beleg.kunde?.designator} />
        <br />
        {beleg.kunde?.zusatz} {beleg.kunde?.iln}
        <br />
        {beleg.kunde?.adresse?.strasse}
        <br />
        {beleg.kunde?.adresse.land} {beleg.kunde?.adresse?.plz} {beleg.kunde?.adresse?.ort}
        <br />
      </div>
      <TextField
        label="KundenBeleg№"
        placeholder="345678"
        value={bezugKunde}
        onChange={(event) => {
          setBezugKunde((event.target as any).value)
        }}
      />
      <TextField
        label="InterneBeleg№"
        placeholder="#47499"
        value={bezugIntern}
        onChange={(event) => {
          setBezugIntern((event.target as any).value)
        }}
      />

      <ComboBox
        selectedKey={selectedKey}
        label="Grund"
        allowFreeform={true}
        options={options}
        onChange={onComboBoxChange}
      />

      <h4>Positionen</h4>

      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Menge</th>
            <th>Item№</th>
            <th>Name</th>
            <th>Einzelpreis</th>
            <th>Summe</th>
            <th>Abschl</th>
            <th>Netto</th>
            <th>Steuer</th>
            <th>Brutto</th>
          </tr>
        </thead>
        <tbody>
          {beleg.leistungen.positionen.map((pos) => (
            <PosEdit
              key={`${pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr}`}
              nullmengen={nullmengen}
              pos={pos}
              onChange={(x) =>
                setNeueLeistungen((prevState) => {
                  return {
                    ...prevState,
                    [pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr]: {
                      ...prevState[pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr],
                      ...x,
                    },
                  }
                })
              }
            />
          ))}
          {zusatzleistungen.map((pos) => (
            <PosEdit
              key={`${pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr}`}
              nullmengen={nullmengen}
              pos={pos}
              onChange={(x) =>
                setNeueZusatzleistungen((prevState) => {
                  return {
                    ...prevState,
                    [pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr]: {
                      ...prevState[pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr],
                      ...x,
                    },
                  }
                })
              }
            />
          ))}

          <tr style={{ borderTop: '1' }}>
            <td></td>
            <td></td>
            <td> </td>
            <td></td>
            <td></td>
            <td style={{ whiteSpace: 'nowrap' }}></td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <ValueRender value={nettoBetrag} format="eurocent" />
            </td>
            <td style={{ whiteSpace: 'nowrap' }}></td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <ValueRender value={bruttoBetrag} format="eurocent" />
            </td>
          </tr>
        </tbody>
      </table>
      <PrimaryButton disabled={saving} text="Speichern" type="submit" onClick={() => uebertragen()}>
        {saving ? <Spinner labelPosition="right" size={SpinnerSize.small} /> : null}
      </PrimaryButton>

      <pre>{JSON.stringify(entwurf, null, 2)}</pre>
    </div>
  )
}

/** Baut den Initial State für Positionen
 */
function _baueState(pos: any, nullmengen: boolean) {
  return [
    pos.posnr_auftrag ?? pos?.bezug?.posnr_auftrag ?? pos.itemnr,
    {
      menge: nullmengen ? 0 : pos.menge,
      einzelpreis: -1 * pos.einzelpreis,
      warenwert: -1 * (nullmengen ? 0 : pos.faktura.warenwert),
      nettoBetrag: -1 * (nullmengen ? 0 : pos.faktura.netto_betrag),
      bruttoBetrag: -1 * (nullmengen ? 0 : pos.faktura.brutto_betrag),
      itemnr: pos.itemnr,
      infotext_kunde: pos?.infotext_kunde,
      name: pos?.name,
      auftragsnr: pos?.bezug?.auftragsnr,
      posnr_auftrag: pos?.bezug?.posnr_auftrag,
      kommiauftragsnr: pos?.bezug?.kommiauftragsnr,
      posnr_kommiauftag: pos?.bezug?.posnr_kommiauftag,
      lieferscheinnr: pos?.bezug?.lieferscheinnr,
      posnr_lieferschein: pos?.bezug?.posnr_lieferschein,
      rechnungsnr: pos?.bezug?.rechnungsnr,
      posnr_rechnung: pos?.bezug?.posnr_rechnung,
    },
  ]
}
export const PosEdit = ({ nullmengen, pos, onChange = (x) => {} }) => {
  const [menge, setMenge] = React.useState<number>(nullmengen ? 0 : pos.menge)
  const [einzelpreis, setEinzelpreis] = React.useState<number>(-1 * pos?.einzelpreis)
  const [infotextKunde, setInfotextKunde] = React.useState<string>(pos?.infotext_kunde)
  const name = pos?.artikel?.name || pos.name

  const steuersatz = pos?.faktura?.steuersatz_prozent
  function berechneWerte(neueMenge, neuerEinzelpreis) {
    const warenwert = neueMenge * neuerEinzelpreis
    const nettoBetrag = warenwert
    const bruttoBetrag = nettoBetrag * (1 + steuersatz / 100)
    return {
      menge: neueMenge,
      einzelpreis: neuerEinzelpreis,
      warenwert,
      nettoBetrag,
      bruttoBetrag,
    }
  }
  const werte = berechneWerte(menge, einzelpreis)

  return (
    <>
      <tr>
        <td>
          <input
            type="number"
            min="0"
            value={menge}
            style={{ width: '5em' }}
            onChange={(event) => {
              const neueMenge = Math.abs(parseInt(event.target.value))
              setMenge(neueMenge)
              onChange(berechneWerte(neueMenge, einzelpreis))
            }}
          />
        </td>
        <td>
          <LinkRender value={pos.itemnr} />
        </td>
        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '5 em' }}>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '5 em' }}>{name}</span>
        </td>
        <td>
          <input
            type="number"
            value={einzelpreis}
            style={{ width: '5em' }}
            onChange={(event) => {
              const neuerEinzelpreis = parseInt(event.target.value)
              setEinzelpreis(neuerEinzelpreis)
              onChange(berechneWerte(menge, neuerEinzelpreis))
            }}
          />
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <ValueRender value={werte.warenwert} format="eurocent" />
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {pos?.faktura?.abschlag_betrag} ({pos?.faktura?.abschlag_prozent}%)
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <ValueRender value={werte.nettoBetrag} format="eurocent" />
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>{steuersatz} %</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <ValueRender value={werte.bruttoBetrag} format="eurocent" />
        </td>
      </tr>
      <tr>
        <td colSpan={9}>
          <input
            type="text"
            value={infotextKunde}
            placeholder="Infotext Kunde"
            style={{ width: '100%' }}
            onChange={(event) => {
              const neuerInfotext = event.target.value
              setInfotextKunde(neuerInfotext)
              onChange({ infotext_kunde: neuerInfotext })
            }}
          />
        </td>
      </tr>
    </>
  )
}
