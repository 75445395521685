// Formatierung von BigQuery Daten.
// Created by Maximillian Dornseif 2020-09-17
// (C) Maximillian Dornseif

import { AgGridColumnProps } from '@ag-grid-community/react'
import { num } from '@hudora/hd-numbro'
import { dateTimeify } from 'any-to-luxon'
import startCase from 'lodash.startcase'

export function buildColumnDefs(schema) {
  // aus der SQL Abfrage die Spaltendefinition generieren
  // damit entscheiden wir auch, wie welche Spalten formatiert werden sollen.
  // Siehe https://www.ag-grid.com/javascript-grid-column-definitions/
  const columnDefs = []
  for (const field of schema) {
    // cellStyle: {textAlign: "right"}
    let col = setColFormat(field)
    // see https://www.ag-grid.com/javascript-grid-column-properties/
    col = {
      colId: field.name,
      field: field.name,
      ...col,
    }
    if (field.list !== true) {
      // for now suppress list fields
      columnDefs.push(col)
    }
  }
  return columnDefs
}
const type2classMap: Record<string, string> = {
  boolean: 'booleanType',
  booleanproperty: 'booleanType',
  date: 'dateType',
  datetime: 'dateTimeType',
  float: 'floatType',
  integer: 'integerType',
  string: 'stringType',
  textproperty: 'stringType',
  timestamp: 'dateTimeType',
}
// Klasse basierend auf dem Ende des Feldnamen setzen.
const nameEndingMap = {
  _euro: ['euroType', ' €'],
  _currency: ['currencyType', ' ¤'],
}
/** AG-Grid Default Format für eine Spalte BigQuery erzeugen
 */
function setColFormat(field): AgGridColumnProps {
  // sets column format based on field (from bigTable)
  const theName = field.name
  // basierend auf dem datentyp
  let col: AgGridColumnProps = {}
  col.cellClass = type2classMap[field.type.toLowerCase()]
  col.type = [field.type.toLowerCase()]

  // basierend auf dem header name
  col.headerName = startCase(theName)

  for (const [ending, tmp] of Object.entries(nameEndingMap)) {
    if (theName.endsWith(ending)) {
      const typ: string = tmp[0]
      const replace: string = tmp[1]
      const strippedName = theName.substring(0, theName.lastIndexOf(ending))
      col.headerName = `${startCase(strippedName)}${replace}`
      col.cellClass = typ
      col.type.push(typ)
    }
  }

  if (col.cellClass) {
    col = { ...col, ...formatTypes[col.cellClass] }
  } else {
    console.error('unknown column cellClass', field.type.toLowerCase(), field)
  }
  return col
}

// Solte alternativ zu ValueRender funktionieren

function valueFormatterEuro(params) {
  // careful: numeral.js seems to have some serious issues, so use numbro.js
  if (isFinite(params.value) && params.value !== null) {
    return num(params.value, {
      mantissa: 2,
    })
  }
  return null
}
function valueFormatterFloat(params) {
  if (isFinite(params.value) && params.value !== null) {
    return num(params.value, {
      mantissa: 2,
    })
  }
  return null
}
function valueFormatterDate(params) {
  return params.value ? dateTimeify(params.value).toISODate() : null
}
function valueFormatterDateTime(params) {
  return params.value ? dateTimeify(params.value).toISO() : null
}
function valueFormatterBoolean(params) {
  if (params.value === false || params.value === 'false') {
    return '❌ nein ' // "batsu" (ばつ)
  } else if (params.value === true || params.value === 'true') {
    return '✅ ja' // alternativ '✔'
  } else {
    return '␀'
  }
}
function valueFormatterString(params) {
  return params.value === null ? '␀' : params.value
}
const formatTypes = {
  currencyType: {
    // Generic Currency
    // Feldname endet mit `_currency`
    // In der Überschrift wird `_currency` durch ` ¤` ersetzt
    valueFormatter: valueFormatterEuro,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: { dataType: 'number', numberFormat: { format: '#,##0' } }
  },
  euroType: {
    // Feldname endet mit `_euro`.
    // In der Überschrift wird `_euro` durch ` €` ersetzt
    valueFormatter: valueFormatterEuro,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
    // excelStyle: { dataType: 'number', numberFormat: { format: '#,##0' } }
  },
  stringType: {
    // wenn der Name auf `nr`, oder `nrs` endet oder `eine_referenz`
    // `referenzen` oder `gruppe` heisst
    // versuchen wir die Nummer(n) zu verlinken
    // excelStyle: { dataType: 'string' }
    valueFormatter: valueFormatterString,
  },
  dateType: {
    // Feldtype ist `date`
    valueFormatter: valueFormatterDate,
    filter: 'agDateColumnFilter',
    // excelStyle: { dataType: 'dateTime', numberFormat: { format: 'yyyy-mm-dd' } }
  },
  dateTimeType: {
    // Feldtype ist `timestamp` oder `datetime`
    valueFormatter: valueFormatterDateTime,
    // excelStyle: {
    //   dataType: 'dateTime',
    //   numberFormat: { format: 'yyyy-mm-dd HH:MM' }
    // }
  },
  floatType: {
    // Feldtype ist `float`
    valueFormatter: valueFormatterFloat,
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
  },
  integerType: {
    cellStyle: { textAlign: 'right' },
    filter: 'agNumberColumnFilter',
  },
  booleanType: {
    valueFormatter: valueFormatterBoolean,
    filter: 'agSetColumnFilter',
    filterParams: { values: [true, false, null] },
  },
}
