// Wrapper für AG-Grid

// Created by Maximillian Dornseif 2018-11-29
// Copyright 2018, 2020 Maximillian Dornseif
// see https://www.ag-grid.com/ag-grid-building-typescript/
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
// eslint-disable-next-line no-unused-vars
import debounce from 'lodash.debounce'
import React from 'react'
import { agGridDefaultOptions } from 'react-use-aggrid-enterprise'

type MyProps = {
  // using `interface` is also ok
  /** additional options for `ag grid` */
  columnDefs?: Array<AgGridColumnProps>
  /** inital row data  */
  rowData: any
  onClick: any
  onDoubleClick: any
  onLayoutChanged: any
  onFirstDataRendered: any
  onGridReady: any
  theme: any
  height: any
}
type MyState = {
  api: any
  columnApi: any
}

export default class HdcMyAgGrid2 extends React.Component<MyProps, MyState> {
  rowData: Array<Record<string, unknown>>
  columnDefs?: Array<AgGridColumnProps>
  propTypes: any

  static defaultProps = {
    theme: 'balham',
    height: '80vh',
    onClick: undefined,
    onDoubleClick: undefined,
    onLayoutChanged: undefined,
  }

  // API-Access-Point speichern
  myOnGridReady = (params) => {
    this.setState({ api: params.api, columnApi: params.columnApi })
    setTimeout(() => params.api.sizeColumnsToFit(), 25)
    // this.optimalColumns()
    // und callback zum Parent weiterleiten
    this.props.onGridReady(params)
  }

  myOnFirstDataRendered = (params) => {
    setTimeout(() => params.api.sizeColumnsToFit(), 25)
    if (this.props.onFirstDataRendered) {
      this.props?.onFirstDataRendered(params)
    }
  }

  autoSizeAll = debounce(() => {
    if (!this.state.columnApi) {
      return
    }
    this.state.columnApi.autoSizeAllColumns(false)
  }, 100)

  render() {
    const { ...options } = this.props
    const gridOptions = {
      // see https://www.ag-grid.com/javascript-grid-events/
      onCellClicked: this.props.onClick,
      onCellDoubleClicked: this.props.onDoubleClick,
      // onColumnMoved: this.onSaveGridColumnState,
      onColumnPinned: this.props.onLayoutChanged,
      onColumnPivotChanged: this.props.onLayoutChanged,
      onColumnPivotModeChanged: this.props.onLayoutChanged,
      onColumnRowGroupChanged: this.props.onLayoutChanged,
      // onColumnValueChanged: this.onLayoutChanged, // A value column was added or removed,
      onColumnVisible: this.props.onLayoutChanged,
      onFilterChanged: this.props.onLayoutChanged,
      onSortChanged: this.props.onLayoutChanged,

      onGridSizeChanged: this.autoSizeAll,
      ...agGridDefaultOptions,
      ...options,
      onGridReady: this.myOnGridReady,
      onFirstDataRendered: this.myOnFirstDataRendered,
    }
    this.rowData = gridOptions.rowData
    this.columnDefs = gridOptions.columnDefs
    return (
      <div className={`ag-theme-${this.props.theme}`} style={{ width: '100%', height: this.props.height }}>
        <AgGridReact {...gridOptions} />
      </div>
    )
  }
}
// HdcMyAgGrid2.propTypes = {
//     /** additional options for `ag grid` */
//     columnDefs: PropTypes.array.isRequired,
//     /** inital row data  */
//     rowData: PropTypes.array.isRequired,
//     onClick: PropTypes.func,
//     onDoubleClick: PropTypes.func,
//     onLayoutChanged: PropTypes.func,
//     onFirstDataRendered: PropTypes.func,
//     onGridReady: PropTypes.func,
//     theme: PropTypes.object,
//     height: PropTypes.number,
// }
