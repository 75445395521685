import { FehlerChecker } from '@hudora/hd-react-components';
import yaml from 'js-yaml';
import PropTypes from 'prop-types';
import React from 'react';
import ReactJson from 'react-json-view';

export function LayoutPanelContent(props) {
  const layout = { ...props.layout };

  // remove uninteresting defs
  layout.columnState = layout.columnState.map((col) => {
    if (col.colId !== 'ag-Grid-AutoColumn') {
      const ret = { colId: col.colId };
      for (const attrname of ['hide', 'aggFunc', 'pivotIndex', 'pinned', 'rowGroupIndex']) {
        if (col[attrname] !== null && col[attrname] !== false) {
          ret[attrname] = col[attrname];
        }
      }
      return ret;
    }
    return col;
  });

  // if ('filter' in layout) {
  //   // remove empty config items
  //   layout = _.pickBy(layout, e => !_.isEmpty(e))
  //   // remove columns hidden but without any other layout
  //   if (layout.columnDefs) {
  //     layout.columnDefs = layout.columnDefs.filter(e => e && !_.isEqual(Object.keys(e), ['colId', 'hide']))
  //   }
  // }
  //      sortModel: this.state.sortModel,
  let content = '';
  let error;

  try {
    content = yaml.dump(layout, { skipInvalid: true });
  } catch (e) {
    error = e;
  }

  return (
    <>
      <h2>Representation des aktuellen Report Layouts.</h2>
      <FehlerChecker error={error} />
      <ReactJson
        src={layout}
        name="Layout"
        collapsed={1}
        collapseStringsAfterLength={30}
        displayDataTypes={false}
        iconStyle="square"
        theme="monokai"
      />
      <pre>{content}</pre>
    </>
  );
}
LayoutPanelContent.propTypes = {
  layout: PropTypes.object,
};
