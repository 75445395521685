// Stammdatenzugriff
// Created 2021-03-24 by Dr. Maximillian Dornseif
// Copyright HUDORA 2021, 2023

import { ISidebarProps, WithSidebar } from 'fluentui-hooks';
import gql from 'graphql-tag';
import React from 'react';

import { KUNDEN_ARTNR_FRAGMENT, KUNDEN_PREIS_FRAGMENT } from '../fragments';
import { KindPage3 } from '../lib/components/ke/KindPage3';
import { KindPagePaginated3 } from '../lib/components/ke/KindPagePaginated3';
import { KindPage } from '../lib/components/KindPage';
import { VA_LIST_QUERY } from '../WaWi/WaWiAppQueries';
import { getStammdatenSidebarLinks } from './StammdatenAaaApp';
import { GruppenApp } from './StammGruppenApp';
import { KundenApp } from './StammKundenApp';
import {
  ARTNR_EDIT_MUTATION,
  KUNDEN_LIST_QUERY_WITH_CRM,
  KUNDE_ENTITY_QUERY,
  VA_EDIT_MUTATION,
  VA_ENTITY_QUERY,
} from './StammKundenAppQueries';
import { VerbaendeApp } from './StammVerbaendeApp';

export const StammdatenApp = (props) => {
  const sidebar: ISidebarProps = {
    Kunden: {
      links: [
        { title: 'Kunden', path: '/stamm/k', component: <KundenApp /> },
        { title: 'Gruppen', path: '/stamm/g', component: <GruppenApp /> },
        { title: 'Verbände', path: '/stamm/v', component: <VerbaendeApp /> },
      ],
    },
    Übersicht: {
      links: [
        { title: 'Kunden Art№', path: '/stamm/kartnr', component: <KundenArtNrApp /> },
        { title: 'Kunden Preise ', path: '/stamm/kpreis', component: <KundenPreisApp /> },
        {
          title: 'Versandanweisungen',
          path: '/stamm/va',
          component: (
            <KindPagePaginated3
              basePath={'/stamm'}
              componentPath={'va'}
              title={'Versandanweisungen'}
              schemaName={'HwVersandanweisung'}
              listQuery={VA_LIST_QUERY}
              entityQuery={VA_ENTITY_QUERY}
              mutation={VA_EDIT_MUTATION}
            />
          ),
        },
        {
          title: 'Kundenliste ',
          path: '/stamm/kundenliste',
          component: (
            <div className="authEnforced">
              <KindPage3
                basePath={'/stamm'}
                componentPath={'kundenliste'}
                schemaName={'HwKunde'}
                title={'Kundenliste'}
                hideExcept={[
                  'ist_aktiv',
                  'designator',
                  'name',
                  'bezeichner',
                  'adresse.plz',
                  'adresse.ort',
                  'adresse.land',
                  'crm.segmentierung',
                  'gruppennr',
                  'gruppe.name',
                  'gruppe.vertreter',
                  'verbandsnr',
                  'intern',
                  'crm.hudora_express',
                  'kreditlimit',
                  'sammelrechnung',
                  'crm.lastschrift',
                  'steuerreferenznr',
                  'kundenrabatt_prozent',
                  'freihausgrenze',
                  'logistikzuschlag_prozent',
                  'kuerzel',
                  'crm.entsorgungssystem',
                  'crm.entsorgernummer',
                ]}
                listQuery={KUNDEN_LIST_QUERY_WITH_CRM}
                entityQuery={KUNDE_ENTITY_QUERY}
              />
            </div>
          ),
        },
      ],
    },
    Verwaltung: {
      links: getStammdatenSidebarLinks(),
    },
  };
  return <WithSidebar items={sidebar} />;
};

const ARTNR_LIST_QUERY = gql`
  query KArtNrListQuery($cursor: String) {
    nodes: hwKundenArtNrPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        cursor
        node {
          ...artnrFragment
          _keyStr
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${KUNDEN_ARTNR_FRAGMENT}
`;
const ARTNR_QUERY = gql`
  query KArtNrQuery($nr: ID!) {
    hwKundenArtNr(designator: $nr) {
      ...artnrFragment
      _keyStr
    }
  }
  ${KUNDEN_ARTNR_FRAGMENT}
`;

export const KundenArtNrApp = () => {
  // Rendert entweder die Startseite (Suche) oder einem Lieferanten
  return (
    <KindPage
      basePath={'/stamm'}
      componentPath={'kartnr'}
      initialLimit={1000}
      schemaName={'HwKundenArtNr'}
      hideExcept={['_tools', 'kundennr', 'artnr', 'name', 'artnr_kunde', 'ist_aktiv', 'ist_gesperrt']}
      title={'Kunden Art№'}
      listQuery={ARTNR_LIST_QUERY}
      entityQuery={ARTNR_QUERY}
      editMutation={ARTNR_EDIT_MUTATION}
      rowToolsEnabled={true}
      bulkEditKeyField="artnr"
    />
  );
};

const PREIS_LIST_QUERY = gql`
  query KPreisList($cursor: String) {
    nodes: hwKundenPreisPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        cursor
        node {
          ...preisFragment
          _keyStr
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${KUNDEN_PREIS_FRAGMENT}
`;
const PREIS_QUERY = gql`
  query KPreisQuery($nr: ID!) {
    hwKundenPreis(designator: $nr) {
      ...preisFragment
      _keyStr
    }
  }
  ${KUNDEN_PREIS_FRAGMENT}
`;

export const KundenPreisApp = () => {
  return (
    <div className="authEnforced">
      <KindPage
        basePath={'/stamm'}
        componentPath={'kpreis'}
        schemaName={'HwKundenPreis'}
        hideExcept={['kundennr', 'artnr', 'name', 'preis', 'ist_aktiv']}
        title={'Kunden Preis'}
        listQuery={PREIS_LIST_QUERY}
        entityQuery={PREIS_QUERY}
      />
    </div>
  );
};
