// Anzeige von Kundendaten
//
// Siehe auch WaWiKundenApp.tsx
//
// Created by Dr. Maximillian Dornseif 2020-10-05
// Copyright 2020, 2023 HUDORA

import Stack from '@mui/material/Stack'
import React from 'react'
import { navigate } from 'wouter/use-location'

import { GruppenCard } from '../lib/components/GruppenCard'
import { IEntitySubPage3 } from '../lib/components/ke/EntityPage3'
import { EntitySubDisplay3 } from '../lib/components/ke/EntitySubDisplay3'
import { KindListe3 } from '../lib/components/ke/KindListe3'
import { KindPage3 } from '../lib/components/ke/KindPage3'
import { KundenCrmCard } from '../lib/components/KundenCrmCard'
import {
  ARTNR_CREATE_MUTATION,
  ARTNR_EDIT_MUTATION,
  ARTNR_LIST_QUERY,
  CRM_EDIT_MUTATION,
  CRM_QUERY,
  EDI_EDIT_MUTATION,
  EDI_QUERY,
  KUNDEN_LIST_QUERY,
  KUNDEN_PREIS_QUERY,
  KUNDEN_UND_GRUPPE_PREIS_QUERY,
  KUNDE_CREATE_MUTATION,
  KUNDE_EDIT_MUTATION,
  KUNDE_ENTITY_QUERY,
  LA_CREATE_MUTATION,
  LA_EDIT_MUTATION,
  LA_LIST_QUERY_MIT_KUNDE,
  PREIS_CREATE_MUTATION,
  PREIS_EDIT_MUTATION,
  SUGGESTIONS_QUERY,
  VA_EDIT_MUTATION,
  VA_ENTITY_QUERY,
} from './StammKundenAppQueries'

/** Rendert entweder die Startseite (Suche) oder eine Kunden-/Gruppen-/Verbandsseite
 * */
export const KundenApp = (props) => {
  const subPages: IEntitySubPage3[] = [
    {
      title: 'CRM',
      path: 'crm',
      render: (entity) => (
        <EntitySubDisplay3
          nr={entity.designator}
          schemaName="H3KundeInfo"
          entityQuery={CRM_QUERY}
          mutation={CRM_EDIT_MUTATION}
        />
      ),
    },
    {
      title: 'EDI',
      path: 'edi',
      render: (entity) => (
        <EntitySubDisplay3
          nr={entity.designator}
          schemaName="HwKundeEdiConfig"
          entityQuery={EDI_QUERY}
          mutation={EDI_EDIT_MUTATION}
        />
      ),
    },
    // HwKundenArtNr Können auf Kunden oder Gruppen bezogen sein.
    {
      title: 'Kundenartikel№',
      path: 'artnr',
      render: (entity) => (
        <KindListe3
          listQuery={ARTNR_LIST_QUERY}
          listQueryVars={{ nr: entity.designator }}
          mutation={ARTNR_EDIT_MUTATION}
          createMutation={ARTNR_CREATE_MUTATION}
          createTemplate={{ kundennr: entity.designator }}
          schemaName="HwKundenArtNr"
          hideExcept={['designator', 'artnr', 'artnr_kunde', 'ist_aktiv', 'ist_gesperrt', 'name']}
          title="Kundennartikelkonfiguration (siehe auch bei den Gruppen)"
          rowToolsEnabled={true}
        />
      ),
    },
    // HwKundenPreis Können auf Kunden oder Gruppen bezogen sein.
    {
      title: 'Kundenpreise',
      path: 'preise',
      render: (entity) => (
        <KindListe3
          listQuery={KUNDEN_PREIS_QUERY}
          listQueryVars={{ nr: entity.designator }}
          mutation={PREIS_EDIT_MUTATION}
          createMutation={PREIS_CREATE_MUTATION}
          createTemplate={{ kundennr: entity.designator }}
          schemaName="HwKundenPreis"
          title="Kundenpreise (siehe auch bei der Gruppe)"
          // hideExcept={['designator', 'artnr', 'artnr_kunde', 'ist_aktiv', 'ist_gesperrt', 'name']}
          rowToolsEnabled={true}
          // bulkEditKeyField="artnr"
        />
      ),
    },
    {
      title: 'Lieferadressen',
      path: 'm',
      render: (entity) => (
        <KindListe3
          listQueryVars={{ nr: entity.designator }}
          listQuery={LA_LIST_QUERY_MIT_KUNDE}
          createMutation={LA_CREATE_MUTATION}
          mutation={LA_EDIT_MUTATION}
          createTemplate={{
            kundennr: entity.designator,
            empfaengernr: entity.designator,
            land: entity.adresse.land,
            ort: entity.adresse.ort,
            plz: entity.adresse.plz,
            versandanweisung: { kundennr: entity.designator },
          }}
          schemaName="HwLieferadresse"
          title="Lieferadressen"
          hideExcept={[
            'designator',
            'name',
            'land',
            'strasse',
            'plz',
            'ort',
            'ist_aktiv',
            'versandanweisung.avisieren_tage',
            'versandanweisung.hebebuehne',
            'versandanweisung.ccg_i',
            'updated_at',
          ]}
          rowToolsEnabled={true}
        />
      ),
    },
    {
      title: 'Versand',
      path: 'versand',
      render: (entity) => (
        <EntitySubDisplay3
          nr={entity.designator}
          schemaName="HwVersandanweisung"
          entityQuery={VA_ENTITY_QUERY}
          mutation={VA_EDIT_MUTATION}
        >
          <div>
            <b>Achtung:</b> Bei uns können Versandanweisungen auf Kunden, Lieferadressen und Aufträge gepflegt
            werden. Versandanweisungen auf Kunden-Ebene werden von Versandanweisungen auf Lieferadressen
            überschrieben, die wiederum von Versandanweisungen auf Aufträge überschrieben werden.
          </div>
        </EntitySubDisplay3>
      ),
    },
    // HwKundenPreis Können auf Kunden oder Gruppen bezogen sein.
    {
      title: 'Kunden- & Gruppenpreise',
      path: 'kgpreise',
      render: (entity) => (
        <KindListe3
          listQuery={KUNDEN_UND_GRUPPE_PREIS_QUERY}
          listQueryVars={{ nr: entity.designator }}
          schemaName="HwKundenPreis"
          title="Kunden- & Gruppenpreise"
          hideExcept={['designator', 'kundennr', 'artnr', 'preis', 'name']}
        />
      ),
    },
  ]

  return (
    <KindPage3
      suggestionQuery={SUGGESTIONS_QUERY}
      basePath={'/stamm'}
      componentPath={'k'}
      schemaName={'HwKunde'}
      title={'Kunden'}
      subPages={subPages}
      initialLimit={250}
      hideExcept={[
        'ist_aktiv',
        'designator',
        'name',
        'bezeichner',
        'adresse.strasse',
        'adresse.plz',
        'adresse.ort',
        'adresse.land',
        'adresse.iln',
        'gruppennr',
        'verbandsnr',
      ]}
      entityQuery={KUNDE_ENTITY_QUERY}
      listQuery={KUNDEN_LIST_QUERY}
      mutation={KUNDE_EDIT_MUTATION}
      createMutation={KUNDE_CREATE_MUTATION}
      entityCommandBarItems={[
        {
          key: 'wawi',
          text: 'in der Warenwirtschaft',
          ariaLabel: 'Kunden-Warenwirtschaft',
          iconProps: {
            iconName: 'Packages',
          },
          iconOnly: false,
          onClick: (e) => {
            navigate(window.location.pathname.replace('/stamm/k/', '/wawi/k/'))
          },
        },
      ]}
      renderEntityPageRightSidebar={(e) => (
        <Stack gap="2em">
          <GruppenCard nr={e?.gruppennr} />
          <KundenCrmCard nr={e?.designator} />
        </Stack>
      )}
    ></KindPage3>
  )
}
