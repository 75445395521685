// Generische Table Funktionalität

// Created by Maximillian Dornseif 2019
// Copyright 2019, 2020 Maximillian Dornseif

import { AgGridColumnProps } from '@ag-grid-community/react'
import { Stack } from '@fluentui/react/lib/Stack'
import { LinkRender } from '@hudora/hd-link'
import debounce from 'lodash.debounce'
import React, { CSSProperties } from 'react'

import HdcMyAgGrid2 from './HdcMyAgGrid2'
import { TableBar } from './HdcTableBar'

// Basiert auf  HdcMyAgGrid (AG-Grid)
// Bietet zusätzlich Suche und erzeugt Spaltendefinitionen bei Bedarf

type THdcTableColumnAddons = Record<string, 'autolink'[]>

export interface IHdcTableProps {
  title: string
  rowData: Record<string, unknown>[]
  columnDefs?: Array<AgGridColumnProps>
  initialLayout?: { filterModel?: any; [key: string]: any }
  columnAddons?: THdcTableColumnAddons
  gridOptions?: any
  onGridReady?: any
  onLayoutChange?: any
  panelItemAddon?: any
  panelAddon?: any
}

export const HdcTable = (props: IHdcTableProps) => {
  return (props?.rowData && props?.rowData.length) > 0 ? (
    <HdcTableInner {...props} />
  ) : (
    <Stack verticalAlign="center">
      <Stack.Item align="center">
        <>(Noch) keine Daten empfangen</>
      </Stack.Item>
    </Stack>
  )
}

type MyState = {
  quickFilter: string
  api?: any
  columnApi?: any
  layout?: any
}
export class HdcTableInner extends React.Component<IHdcTableProps, MyState> {
  static defaultProps = { columnAddons: { designator: ['autolink'] } } // siehe unten

  constructor(props) {
    super(props)
    this.state = { quickFilter: '', api: undefined, columnApi: undefined }
  }

  myOnGridReady = (params) => {
    this.setState({ api: params.api, columnApi: params.columnApi })
    if (this.props.onGridReady) {
      this.props.onGridReady(params)
    }
  }

  myOnFirstDataRendered = (params) => {
    this.onLayoutChanged()
    if (this.props?.initialLayout?.filterModel) {
      params.api.setFilterModel(this.props?.initialLayout?.filterModel)
    }
  }

  // called after a sort/grouping/filter change
  onLayoutChanged = debounce(() => {
    // track the state of the Grid
    // see https://blog.ag-grid.com/persisting-ag-grid-state-with-react-redux/

    const layout = {
      filterModel: this.state.api?.getFilterModel(),
      isPivotMode: this.state.columnApi.isPivotMode(),
      quickFilter: this.state.quickFilter,
      columnState: this.state.columnApi
        ?.getColumnState()
        .filter((col) => !col.colId.startsWith('ag-Grid-AutoColumn')),
      columnGroupState: this.state.columnApi?.getColumnGroupState(),
    }
    this.setState({ layout })
    if (this.props.onLayoutChange) {
      this.props.onLayoutChange(layout)
    }
  }, 200)

  handleQuickFilterChange = (event) => {
    this.setState({ quickFilter: event.target.value })
    this.state.api.setQuickFilter(event.target.value)
  }

  /** fügt alle Spalten in rowData zu den schon übergebenen Spaltendefinitionen hinzu
   *
   * Wendet allerlei Domain Knoowledge an.
   * **/
  getExtendedColumnDefs = () => {
    const newCols = this.props.columnDefs ? [...this.props.columnDefs] : []
    const known = this.props.columnDefs ? this.props.columnDefs.map((c) => c.field) : []
    if (this.props?.rowData && this.props?.rowData.length > 0) {
      // Erste Zeile der Daten nach properties durchsuchen
      for (const key of Object.keys(this.props.rowData[0])) {
        if (!known.includes(key)) {
          // ein neues, vom Aufrufer nicht übergebenes Feld
          const colDef: AgGridColumnProps = { field: key }
          if (this.props.columnDefs) {
            colDef.hide = true
          }
          if (key.startsWith('_') || key === 'id') {
            colDef.hide = true
          }
          newCols.push(colDef)
        }
      }
    }
    // Jetzt fügen wir die Eigenschaften aus columnAddons zu.
    const ret = []
    for (const col of newCols) {
      const def = this.props.columnAddons?.[col.field]
      if (def) {
        // https://www.ag-grid.com/javascript-grid-column-properties/
        for (const flag of def) {
          switch (flag) {
            case 'autolink':
              col.cellRenderer = LinkRender
              break
            default:
              console.error(`unknown flag ${def}.`)
          }
        }
      }
      ret.push(col)
    }
    // Nun gehen wir die Spalten durch und schauen, was da noch zu tun ist.
    return ret
  }

  // TODO: avoid Click on DoubleClick
  // see https://css-tricks.com/snippets/javascript/bind-different-events-to-click-and-double-click/
  // https://stackoverflow.com/a/36160869/49407
  // defaultColDef https://www.ag-grid.com/javascript-grid-column-definitions/

  render() {
    const margin: CSSProperties = { margin: 'auto 0.3em', whiteSpace: 'nowrap' }
    const gridOptions = {
      onGridReady: this.myOnGridReady,
      onLayoutChanged: this.onLayoutChanged,
      onFirstDataRendered: this.myOnFirstDataRendered,
      ...this.props.gridOptions,
    }

    // filterModel":{"eingang_kw":{"values":["2020-W37"],"filterType":"set"}}
    return (
      <div>
        <div style={margin}>
          <TableBar
            value={this.state.quickFilter}
            onChange={this.handleQuickFilterChange}
            layout={this.state.layout}
            panelItemAddon={this.props.panelItemAddon}
            panelAddon={this.props.panelAddon}
          />
        </div>
        <HdcMyAgGrid2
          {...gridOptions}
          rowData={this.props.rowData}
          columnDefs={this.getExtendedColumnDefs()}
        />
      </div>
    )
  }
}
