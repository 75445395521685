import { ICommandBarItemProps } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { Stack } from '@fluentui/react/lib/Stack'
import { assertIsString } from 'assertate-debug'
import { useTitle } from 'fluentui-hooks'
import React from 'react'

import { TListQueryOptions } from '../../types'
import { EntityTable, IEntityTableProps } from './EntityTable'
import { IKindListeProps } from './KindListe'
import { LadeChecker } from './UiChecker'

interface KTTheEntityTableProps extends IEntityTableProps {
  title: string
  createPanel: React.ReactElement
  createButton: React.ReactElement
  commandBarItems: Array<ICommandBarItemProps>
  error: any
  loading: boolean
  networkStatus: number
  internalDesignatorLink?: boolean
  className?: string
}
/**
 * @deprecated
 */
export const KTTheEntityTable = (props: KTTheEntityTableProps) => {
  assertIsString(props.schemaName, 'schemaName muss gesetzt sein')
  useTitle(props.title)
  return (
    <Stack
      className={props.className}
      aria-label={'Entity Liste'}
      tokens={{
        childrenGap: '0.2em',
      }}
    >
      {props.createPanel}
      <LadeChecker loading={props.loading} error={props.error} />
      {!props.loading && props.rowData && props.rowData.length < 1 ? (
        <KTEmptyTable
          createButton={props.createButton}
          error={props.error}
          title={props.title}
        ></KTEmptyTable>
      ) : (
        <EntityTable
          {...props}
          rowData={props.rowData}
          hideExcept={props.hideExcept}
          commandBarItems={props.commandBarItems}
          internalDesignatorLink={props.internalDesignatorLink || false}
        />
      )}
      <div>{props.networkStatus < 7 ? <Spinner size={SpinnerSize.xSmall} /> : null}</div>
    </Stack>
  )
}
// Das zeigen wir an, wenn es keine Daten gibt

export const KTEmptyTable = (props) => (
  <div>
    {props.title !== '' ? <h2>{props.title}</h2> : null}
    <Stack verticalAlign="center">
      <Stack.Item align="center">
        {props.createButton ? (
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {props.createButton}
          </div>
        ) : props.error ? (
          <>
            Fehler: <pre>{JSON.stringify(props.error, null, 2)}</pre>
          </>
        ) : (
          <>Keine Einträge</>
        )}
      </Stack.Item>
    </Stack>
  </div>
)
export function ktUpdateListQueryOptions(props: IKindListeProps) {
  const listQueryOptions: TListQueryOptions = props.listQueryOptions || { variables: {} }
  listQueryOptions.variables = {
    ...((listQueryOptions.variables as Record<string, any> | undefined) || {}),
  }
  if (props?.nr) {
    listQueryOptions.variables.nr = props.nr
  }
  return listQueryOptions
}
