/*
 * usePaginatedQuery.tsx - from https://gist.github.com/nandorojo/1a2ced518dc6d0ca526dad2b5b0bd0d0
 *
 * Created by Dr. Maximillian Dornseif 2023-11-05 in huWaWi3 33.4.0
 * Copyright 2023, Fernando Rojo. Free to use.
 */

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { assertIsArray } from 'assertate-debug';
import { DocumentNode } from 'graphql';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import deepEqual from 'react-fast-compare';
import { AnyVariables, UseQueryArgs, useClient, useQuery } from 'urql';

const useServerLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

type Options<V extends AnyVariables = AnyVariables> = Omit<UseQueryArgs<V>, 'query' | 'variables'> & {
  variables: Omit<V, 'offset'>;
};
/**
 * Variables should be memoized here.
 */
export function usePaginatedQuery<
  D extends {
    nodes: { pageInfo: { endCursor: string; hasNextPage: boolean }; edges: Array<Record<string, any>> };
  },
  V extends {} = {}
>(
  //  queryDocument: TypedDocumentNode<D, V>,
  queryDocument: DocumentNode,
  options: Options<V>,
  { keepPreviousNonEmptyData }: { keepPreviousNonEmptyData?: boolean }
) {
  const [cursor, setCursor] = useState('');
  const [nodes, setNodes] = useState([]);

  // const client = useClient()
  // @ts-expect-error i think it's fine, just generic stuff
  const [query, executeQuery] = useQuery<D, V>({
    query: queryDocument,
    ...options,
    variables: useMemo(
      () => ({
        ...options.variables,
        cursor,
      }),
      [options.variables, cursor]
    ),
  });

  const previousNonEmptyData = useRef<typeof query.data>();
  useEffect(() => {
    if (query.data && query.data?.nodes?.edges?.length > 0) {
      previousNonEmptyData.current = query.data;
    }
    setNodes((prev) => {
      if (query?.data?.nodes?.edges?.length > 1) {
        assertIsArray(query?.data?.nodes?.edges);
        return [...prev, ...query.data.nodes.edges.map((x) => x.node)];
      }
      return prev;
    });
  }, [query.data, setNodes]);

  const { data } = query;

  const isFetchingMore = Boolean((query.fetching || query.stale) && query.data && cursor !== '');

  const isLoadingInitial = Boolean(
    previousNonEmptyData.current?.nodes?.pageInfo?.endCursor === '' &&
      (query.fetching || query.stale) &&
      !query.data &&
      !query.error
  );

  const canFetchMore = Boolean(
    previousNonEmptyData.current?.nodes?.pageInfo?.hasNextPage && !query.fetching && !query.error
  );

  const fetchMore = useCallback(
    () => canFetchMore && setCursor(previousNonEmptyData.current?.nodes?.pageInfo?.endCursor),
    [canFetchMore, setCursor]
  );

  // const revalidate = useCallback(
  //   () => executeQuery({ requestPolicy: 'network-only' }),
  //   [executeQuery]
  // )

  // const pullToRefresh = useCallback(() => {
  //   revalidate()
  //   setPage(1)
  // }, [revalidate])

  const variablesToCompare = useMemo(
    () => ({
      ...options.variables,
    }),
    [options.variables]
  );

  const previousVariables = useRef(variablesToCompare);

  useServerLayoutEffect(
    function setFirstPageOnMeaningfulVariableChange() {
      if (!deepEqual(previousVariables.current, variablesToCompare)) {
        setCursor('');
      }
      previousVariables.current = variablesToCompare;
    },
    // this isn't a deep-equal check, but it at least is better than nothing
    // "fast-deep-equal" will do the most on most renders. idk a way around it
    Object.values(variablesToCompare)
  );
  // const [isPullingToRefresh, setPullingToRefresh] = useState(false)

  // const timer = useRef(0)

  // const onPullToRefresh = useCallback(() => {
  //   clearTimeout(timer.current)
  //   setPullingToRefresh(true)
  //   executeQuery({
  //     requestPolicy: 'network-only',
  //   })
  //   new Promise<void>((resolve) => {
  //     timer.current = setTimeout(() => {
  //       clearTimeout(timer.current)
  //       resolve()
  //     }, 1000)
  //   })
  //     .catch()
  //     .finally(() => {
  //       setPullingToRefresh(false)
  //     })
  // }, [executeQuery])

  // useEffect(
  //   () => () => {
  //     clearTimeout(timer.current)
  //   },
  //   []
  // )

  return {
    fetching: query.fetching,
    stale: query.stale,
    error: query.error,
    operation: query.operation,
    variables: query.operation?.variables,
    data: (() => {
      if (
        keepPreviousNonEmptyData &&
        query.data &&
        query.data?.nodes?.edges?.length === 0 &&
        previousNonEmptyData.current &&
        previousNonEmptyData.current.nodes?.edges?.length > 0
      ) {
        return previousNonEmptyData.current;
      }
      return data;
    })(),
    nodes,
    // isPullingToRefresh,
    isLoadingInitial,
    // onPullToRefresh,
    execute: useCallback(async () => {
      return executeQuery({
        requestPolicy: 'network-only',
      });
    }, [executeQuery]),
    canFetchMore,
    isFetchingMore,
    fetchMore,
    // revalidate,
    // pullToRefresh,
  };
}
