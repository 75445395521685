import { useConstCallback } from '@fluentui/react-hooks'
import { CommandBar } from '@fluentui/react/lib/CommandBar'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import PropTypes from 'prop-types'
import React from 'react'

import { LayoutPanelContent } from './HdcTableLayoutPanelContent'

export const searchStyles = {
  root: {
    width: '220px',
  },
}

export const TableBar = (props: {
  value: any
  onChange: any
  layout: any
  panelItemAddon: any
  panelAddon: any
}) => {
  const [isLayoutPanelOpen, setIsLayoutPanelOpen] = React.useState(false)
  const openLayoutPanel = useConstCallback(() => setIsLayoutPanelOpen(true))
  const dismissLayoutPanel = useConstCallback(() => setIsLayoutPanelOpen(false))
  // const [isFullscreenMode, setIsFullscreenMode] = React.useState(false)
  // const openFullscreenMode = useConstCallback(() => setIsFullscreenMode(true))
  // const dismissFullscreenMode = useConstCallback(() =>
  //   setIsFullscreenMode(false)
  // )
  const _items = [
    {
      key: 'search',
      onRender: () => (
        <SearchBox
          placeholder="Tabellen-Suche"
          underlined
          styles={searchStyles}
          iconProps={{ iconName: 'Filter' }}
          value={props.value}
          onChange={props.onChange}
        />
      ),
    },
  ]

  const _farItems = [
    // {
    //   key: 'enlarge',
    //   text: 'Groß',
    //   title: 'Möglichst Groß darstellen',
    //   ariaLabel: 'Grid view',
    //   iconOnly: false,
    //   iconProps: { iconName: 'FullScreen' },
    //   onClick: () => alert('Kommt noch'),
    // },
    // {
    //   key: 'sql',
    //   text: 'SQL',
    //   title: 'Datenbank abfrage',
    //   ariaLabel: 'SQL view',
    //   iconOnly: false,
    //   iconProps: { iconName: 'FileSQL' },
    //   onClick: () => alert('Kommt noch')
    // },
    {
      key: 'layout',
      text: 'Layout',
      title: 'Darstellung',
      // This needs an ariaLabel since it's icon-only
      ariaLabel: 'Layout',
      iconOnly: false,
      iconProps: { iconName: 'FileTemplate' },
      onClick: openLayoutPanel,
    },
  ]

  if (props.panelItemAddon) {
    _farItems.unshift(props.panelItemAddon)
  }

  // https://developer.microsoft.com/en-us/fabric-js/components/commandbar/commandbar
  return (
    <div>
      <CommandBar items={_items} farItems={_farItems} />
      <Panel isOpen={isLayoutPanelOpen} onDismiss={dismissLayoutPanel} type={PanelType.medium}>
        <h2>Report-Layout</h2>
        <LayoutPanelContent layout={props.layout} />
      </Panel>
      {props.panelAddon ? props.panelAddon : null}
    </div>
  )
}
TableBar.propTypes = {
  layout: PropTypes.object,
  panelItemAddon: PropTypes.object,
  panelAddon: PropTypes.object,
}
