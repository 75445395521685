import { useQuery } from '@apollo/client';
import { FehlerChecker, LadeChecker } from '@hudora/hd-react-components';
import { assertIsString } from 'assertate-debug';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import ReactJson from 'react-json-view';

import { prettySize } from './BigQueryView';

export function BigQuerySqlPanelContent(props) {
  const { loading, error, data } = useQuery(SQL_QUERY, {
    variables: {
      sqlName: props.sqlName,
      jobId: props.jobId,
    },
    skip: !props.sqlName, // when there is no sqlName
  });

  let metadata: Record<string, any> = {};
  try {
    metadata = JSON.parse(data.sqlJobInfo.metadataJson);
    // eslint-disable-next-line no-empty
  } catch (e) {
    console.log('error parsing metadata', e);
  }
  // statistics.totalBytesProcessed
  // statistics.query.cacheHit
  // statistics.query.referencedTables.map(t => `${t.datasetId}.${tableId}`)
  let metaInfo: any = null;
  if (metadata?.statistics) {
    const tablelist = metadata?.statistics?.query?.referencedTables ? (
      metadata?.statistics?.query?.referencedTables.map((t) => (
        <li key={`${t.datasetId}.${t.tableId}`}>{`${t.datasetId}.${t.tableId}`}</li>
      ))
    ) : (
      <li>
        <em>unbekannt</em>
      </li>
    );

    if (metadata?.statistics?.query?.cacheHit) {
      metaInfo = <>Die Daten wurden kostenfrei aus dem Cache gelesen</>;
    } else {
      metaInfo = (
        <p>
          Es wurden in letzter Konsequenz folgende Tabellen abgefragt: <ul>{tablelist}</ul>
          Dabei wurden {prettySize(metadata?.statistics?.totalBytesProcessed)} gelesen. Das entspricht etwa
          Kosten von{' '}
          {(
            Number.parseFloat(`${metadata?.statistics?.totalBytesProcessed * 500}`) /
            1000 /
            1000 /
            1000 /
            1000
          ).toFixed(4)}{' '}
          Cent.
        </p>
      );
    }
  }

  if (data?.sqlQueryText) {
    assertIsString(data?.sqlQueryText);
  }
  return (
    <>
      <h2>BigQuery Datenbankabfrage {props.sqlName}</h2>
      <FehlerChecker error={error} />
      {metaInfo}
      <p>
        Zugriff unter{' '}
        <a
          href={`https://console.cloud.google.com/bigquery?project=huwawisql&j=bq:EU:${props.jobId}&page=queryresults`}
        >
          {props.jobId}
        </a>
        , wenn Sie die entsprechende Google Berechtigung (bigquery.admin) haben.
      </p>
      <FehlerChecker error={error} />
      <LadeChecker loading={loading} />
      {data?.sqlQueryText ? (
        Array.isArray() ? (
          <pre>{data?.sqlQueryText.join()}</pre>
        ) : (
          <pre>{data?.sqlQueryText}</pre>
        )
      ) : null}
      <ReactJson src={metadata} />
    </>
  );
}
const SQL_QUERY = gql`
  query SqlInfoQuery($sqlName: String!, $jobId: ID!) {
    sqlQueryText(name: $sqlName)
    sqlJobInfo(id: $jobId) {
      metadataJson
    }
  }
`;
BigQuerySqlPanelContent.propTypes = {
  jobId: PropTypes.string.isRequired,
  sqlName: PropTypes.string.isRequired,
};
