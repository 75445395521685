// Anzeige von Aufträgen
// Created by Dr. Maximillian Dornseif 2020-10-05
// Copyright 2020, 2022 HUDORA

import React from 'react'
import { Route } from 'wouter'

import { KindListePaginated3 } from '../../lib/components/ke/KindListePaginated3'
import { AUFTRAG_LIST_QUERY } from './WaWiAuftragAppQueries'
import { EinAuftragApp } from './WaWiAuftragEiner'

export function WaWiIcApp() {
  return (
    <>
      <Route path="/wawi/ic/a">
        <KindListePaginated3
          title={'Aufträge'}
          schemaName={'HwAuftrag'}
          listQuery={AUFTRAG_LIST_QUERY}
          hideExcept={[
            'auftragsnr',
            'empfaengernr',
            'name',
            'auftragsnr_kunde',
            'anlieferdatum_von',
            'anlieferdatum_bis',
            'status',
            'erledigt',
            'created_via',
            'zuteilungssperre',
          ]}
        />
      </Route>
      <Route path="/wawi/ic/a/:nr">{(params: any) => <EinAuftragApp nr={params.nr} />}</Route>
    </>
  )
}
