// huWaWi Seite
// Created in 2021-05-05 by Maximillian Dornseif
// Copyright HUDORA 2021

import { Stack } from '@fluentui/react/lib/Stack'
import algoliasearch from 'algoliasearch/lite'
import startCase from 'lodash.startcase'
import React from 'react'
import {
  ClearRefinements,
  CurrentRefinements,
  Highlight,
  InfiniteHits,
  InstantSearch,
  Panel,
  RefinementList,
  SearchBox,
  Stats,
  connectStateResults,
} from 'react-instantsearch-dom'

const searchClient = algoliasearch('7S5OG4REWY', '46e7c4d8a9a54872993d78818f6ea79c')

export function SearchPageApp(props) {
  const refinementElements = ['schema', 'istAktiv', 'istStammdaten', 'felder.land'].map((x) => (
    <MyRefinmentList key={x} name={x} />
  ))

  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="huwawi3">
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.4.5/themes/satellite-min.css"
          integrity="sha256-TehzF/2QvNKhGQrrNpoOb2Ck4iGZ1J/DI4pkd2oUsBc="
          crossOrigin="anonymous"
        />
        <Stack tokens={{ childrenGap: '2em' }}>
          <h2>Such-Test</h2>
          <div>
            <SearchBox showLoadingIndicator autoFocus />
            <Stats />
            <CurrentRefinements />
          </div>
          <Stack horizontal tokens={{ childrenGap: '2em' }}>
            <div>
              <h2>Filter</h2>
              <ClearRefinements />
              {refinementElements}
            </div>
            <Results>
              <InfiniteHits hitComponent={Hit} />
            </Results>
          </Stack>
        </Stack>
      </InstantSearch>
    </div>
  )
}

/** Nichts ausgeben, wenn auch ncihts gesucht wurde
 * */
const Results = connectStateResults(({ searchState, children }) => {
  return Object.keys(searchState).length === 0 ? <div></div> : children
})

function Hit({ hit }) {
  const style = hit.istAktiv !== false ? {} : { textDecoration: 'line-through' }
  return (
    <div>
      <span style={style}>
        <a href={hit.url}>
          <Highlight hit={hit} attribute="title" tagName="strong" />
        </a>
      </span>
      <br />
      <HitFelder hit={hit} />
    </div>
  )
}

function HitFelder({ hit }) {
  const ret = []
  for (const name of Object.keys(hit?.felder)) {
    if (hit?.felder?.[name] && hit?.felder?.[name] !== '') {
      // Vermeiden von Eintragen, die so auch im Titel vorkommen
      if (!hit?.title.includes(hit?.felder[name])) {
        // <Highlight> klappt nicht mit allen Feldern
        if (hit?._highlightResult.felder?.[name]) {
          ret.push(
            <span key={name}>
              <small style={{ textColor: '#888888' }}>{name}:</small>
              <Highlight hit={hit} attribute={`felder.${name}`} />
            </span>
          )
        } else {
          ret.push(
            <span key={name}>
              <small style={{ textColor: '#888888' }}>{name}:</small>
              {hit?.felder[name]}
            </span>
          )
        }
      }
    }
    ret.push(' ')
  }
  return <>{ret}</>
}

export function MyRefinmentList(props) {
  return (
    <Panel header={startCase(props.name)}>
      <RefinementList attribute={props.name} />
    </Panel>
  )
}
