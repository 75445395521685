import gql from 'graphql-tag';
import React from 'react';

import { BigQueryView } from '../components/BigQueryView';
import { EntityPage, IEntitySubPage } from '../lib/components/EntityPage';

const BUCHUNGS_LAYOUT = {
  columnState: [
    {
      colId: 'lager',
    },
    {
      colId: 'datum',
    },
    {
      colId: 'artnr',
    },
    {
      colId: 'menge',
    },
    {
      colId: 'buchungsnr',
    },
    {
      colId: 'vorgang',
    },
    {
      colId: 'beleg',
    },
  ],
};

const subPages: IEntitySubPage[] = [
  {
    title: 'Sonderbuchungen',
    path: 'sonder',
    component: (
      <BigQueryView
        path="sonder"
        sqlName="LagerSonderBuchungen"
        title={`Im Vorjahr durchgeführte Sonderbuchungen`}
        initialLayout={BUCHUNGS_LAYOUT}
      />
    ),
  },
  {
    title: 'bebuchte Läger',
    path: 'bebucht',
    component: (
      <BigQueryView path="bebucht" sqlName="LagerBebucht" title={`Im Vorjahr bebuchte Läger und Artikel`} />
    ),
  },
  {
    title: 'Endbestand',
    path: 'endbestand',
    component: <BigQueryView path="endbestand" sqlName="LagerJahresendbestand" title={`Jahresendbestand`} />,
  },
];

export const IcConfigApp = () => {
  return (
    <div className="authEnforced">
      <EntityPage
        basePath="/ic/config"
        entityQuery={CONFIG_QUERY}
        editMutation={EDIT_MUTATION}
        nr="SYSCONF1"
        schemaName={'HwSystemKonfiguration'}
        subPages={subPages}
      />
    </div>
  );
};

const CONFIG_QUERY = gql`
  query Config {
    entity: hwSystemKonfiguration {
      id
      designator
      freie_ean_suffix
      bestandsbuchverarbeitung_aktiv
      bestandsbuch_verbuchen_aktiv
      bestandsbuch_verbuchen_2
      inventur_aktiv
      bestandsbuch_neu_generieren_aktiv
      artikelsumme_neu_genrieren_aktiv
      rueckbuchen_aktiv
      zuteilungslauf_aktiv
      zuteilung_aktiv
      bestandsbuch_negativbestand_erlaubt
      ean_prefix
      status_updates_ueber200_aktiv
      anbietr_image_size
      rechnungsausgangsjournal_an
      rechnungslauf_aktiv
      export_kommiauftraege
      ic_auftragsverarbeitung_max
      status_updates_wochenende
      status_updates_unter200_aktiv
      zuteilungsvorlauf_keine_teillieferung
      zuteilungsvorlauf_teillieferung
      zuteilungs_cooloff
      zuteilungshorizont
      verspaetete_kommiauftraege_an
      stammdatenfehler_an
      lager_dispodaten_an
      created_at
      updated_at
    }
  }
`;

const EDIT_MUTATION = gql`
  mutation change($designator: ID!, $input: HwSystemKonfigurationInput!) {
    updateHwSystemKonfiguration(designator: $designator, input: $input) {
      id
      designator
      freie_ean_suffix
      bestandsbuchverarbeitung_aktiv
      bestandsbuch_verbuchen_aktiv
      bestandsbuch_verbuchen_2
      inventur_aktiv
      bestandsbuch_neu_generieren_aktiv
      artikelsumme_neu_genrieren_aktiv
      rueckbuchen_aktiv
      zuteilungslauf_aktiv
      zuteilung_aktiv
      bestandsbuch_negativbestand_erlaubt
      ean_prefix
      status_updates_ueber200_aktiv
      anbietr_image_size
      rechnungsausgangsjournal_an
      rechnungslauf_aktiv
      export_kommiauftraege
      ic_auftragsverarbeitung_max
      status_updates_wochenende
      status_updates_unter200_aktiv
      zuteilungsvorlauf_keine_teillieferung
      zuteilungsvorlauf_teillieferung
      zuteilungs_cooloff
      zuteilungshorizont
      verspaetete_kommiauftraege_an
      stammdatenfehler_an
      lager_dispodaten_an

      created_at
      updated_at
    }
  }
`;
