import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import addMoreFormats from 'ajv-formats-draft2019'
import { JSONSchema7 } from 'json-schema'

export function dataFitsSchema(currentFormData, schema: JSONSchema7): boolean {
  const ajv = new Ajv({ strict: false }) // options can be passed, e.g. {allErrors: true}
  addFormats(ajv)
  addMoreFormats(ajv)
  ajv.addFormat('eurocent', /\d*/)
  ajv.addFormat('eurocentlarge', /\d*/)
  ajv.addFormat('markdown', /\d*/)
  const validate = ajv.compile(schema || false)
  const valid = validate(currentFormData)
  //   if (!valid) {
  //     console.log(validate.errors)
  //   }
  return valid
}
