import { assertIsDefined, assertIsString } from 'assertate-debug'
import * as React from 'react'
import toast from 'react-hot-toast'
import { AnyVariables, OperationResult, useMutation } from 'urql'

export function useToastMutation({ mutation, variables, fieldTitle }) {
  variables = variables || {}
  const [result1, mutateEntity] = useMutation(mutation)
  let result2

  const uebertragen = (moreVariables) => {
    // setDisabled(true)
    assertIsString(fieldTitle, 'der Feldtitel fehlt')
    const prom: Promise<OperationResult<any, AnyVariables>> = (async () => {
      // Laut https://formidable.com/open-source/urql/docs/api/urql/#usemutation
      // sollte mutateEntity() ein Promise mit zurückgeben, aber das ist scheinbar nicht so.
      // "It returns a Promise resolving to an OperationResult."
      // https://formidable.com/open-source/urql/docs/api/core/#operationresult
      result2 = await mutateEntity({
        ...variables,
        ...moreVariables,
      })
      assertIsDefined(result2, 'result2')
      // scheinbar kommen hier gar keine Fehler an
      if (result2.error) throw result2.error
      if (!result2.data?.result?.success && result2.data?.result?.message) {
        throw new Error(result2.data?.result?.message)
      }
      return result2
    })()

    toast.promise(prom, {
      loading: `Aktualisiere ${fieldTitle}`,
      success: (r: OperationResult<any, AnyVariables>) => {
        return (
          <React.Fragment>
            Erfolgreich {fieldTitle} geändert ({Number.parseFloat(result2.data?.result?.duration).toFixed(0)}{' '}
            ms).
          </React.Fragment>
        )
      },
      error: (e) => {
        // dismissDialog()
        // setDisabled(false)
        console.error(e)
        return `Fehler: ${e}`
      },
    })
    return prom
  }

  const result = { ...result2, ...result1 }
  return [result, uebertragen]
}
