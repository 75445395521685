import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { usePanel } from 'fluentui-hooks'
import PropTypes from 'prop-types'
import React from 'react'

import { HdcTable, IHdcTableProps } from '../hdjslib/components/HdcTable'
import { BigQuerySqlPanelContent } from './BigQuerySqlPanelContent'

export function BigQueryHdcTableWithSqlPanel(props: IHdcTableProps & { jobId: string; sqlName: string }) {
  const [openSqlPanel, sqlPanelProps] = usePanel(`Datenbank Abfrage`)

  const panelItem = {
    key: 'sql',
    text: 'SQL',
    title: 'Datenbank Abfrage',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'SQL',
    iconOnly: false,
    iconProps: { iconName: 'Database' },
    onClick: openSqlPanel,
  }

  const panel = (
    <Panel {...sqlPanelProps} type={PanelType.medium}>
      <BigQuerySqlPanelContent jobId={props.jobId} sqlName={props.sqlName} />
    </Panel>
  )

  return <HdcTable panelItemAddon={panelItem} panelAddon={panel} {...props} />
}
BigQueryHdcTableWithSqlPanel.propTypes = {
  jobId: PropTypes.string.isRequired,
  sqlName: PropTypes.string.isRequired,
}
