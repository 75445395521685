import gql from 'graphql-tag';

import { GRUPPE_FRAGMENT, KUNDEN_ARTNR_FRAGMENT, KUNDEN_PREIS_FRAGMENT, KUNDE_FRAGMENT } from '../fragments';

export const GRUPPE_SUGGESTIONS_QUERY = gql`
  query SearchGruppenQuery($term: String!) {
    fulltextSearch: hwKundenGruppeSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`;

export const GRUPPE_LIST_QUERY = gql`
  query AlleGruppen($cursor: String) {
    nodes: hwKundenGruppePages(after: $cursor, sort: { order: DESC, fields: updated_at }) {
      edges {
        # cursor
        node {
          ...gruppeFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${GRUPPE_FRAGMENT}
`;

export const GRUPPE_ENTITY_QUERY = gql`
  query EineGruppeQuery($nr: ID!) {
    entity: hwKundenGruppe(designator: $nr) {
      ...gruppeFragment
    }
  }
  ${GRUPPE_FRAGMENT}
`;
export const GRUPPE_CREATE_MUTATION = gql`
  mutation GruppeCreateQuery($input: HwKundenGruppeInput!) {
    entity: hwKundenGruppeCreate(input: $input) {
      ...gruppeFragment
    }
  }
  ${GRUPPE_FRAGMENT}
`;

export const GRUPPE_EDIT_MUTATION = gql`
  mutation GruppeAendern($designator: ID!, $input: HwKundenGruppePatchInput!, $oldRev: ID!) {
    patchInfo: hwKundenGruppePatch(designator: $designator, input: $input, oldRev: $oldRev) {
      old {
        ...gruppeFragment
      }
      new {
        ...gruppeFragment
      }
      oldRev
      newRev
      duration
      success
      errors
      audit {
        created_at
        typ
        uid
        user
        text
        new
        old
      }
    }
  }
  ${GRUPPE_FRAGMENT}
`;
export const GRUPPEN_MITGLIEDER_QUERY = gql`
  query GruppeMitgliederQuery($nr: ID!) {
    entity: hwKundenGruppe(designator: $nr) {
      id
      nodes: kunden {
        ...kundeFragment
      }
    }
  }
  ${KUNDE_FRAGMENT}
`;
export const GRUPPEN_PREISE_QUERY = gql`
  query KundenpreiseGruppeQuery($nr: ID!) {
    entity: hwKundenGruppe(designator: $nr) {
      id
      nodes: kundenpreise {
        ...preisFragment
      }
    }
  }
  ${KUNDEN_PREIS_FRAGMENT}
`;
export const GRUPPEN_ARTNR_QUERY = gql`
  query KundenartikelnummernGruppeQuery($nr: ID!) {
    entity: hwKundenGruppe(designator: $nr) {
      id
      nodes: kundenartikelnummern {
        ...artnrFragment
      }
    }
  }
  ${KUNDEN_ARTNR_FRAGMENT}
`;
