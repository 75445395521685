// Formular für EntityMutate
// basiert auf https://github.com/rjsf-team/react-jsonschema-form
//
// Created by Dr. Maximillian Dornseif 2021-09-27
// Copyright 2021, 2023 Dr. Maximillian Dornseif

import { MessageBar, MessageBarType, Stack } from '@fluentui/react'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Separator } from '@fluentui/react/lib/Separator'
import Form from '@hudora/hd-rjsf'
import { assertIsObject, assertIsString } from 'assertate-debug'
import { cleanDiff, cleanGqlInput } from 'graphql-clean-diff'
import { JSONSchema7 } from 'json-schema'
import { jsonEmptyStrings } from 'json-schema-empty-strings'
import { jsonSchemaDataMerge } from 'json-schema-prepare-data-for-form'
import transform from 'lodash.transform'
import React, { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import ReactJson from 'react-json-view'

import { IEntity } from '../../types'
import { dataFitsSchema } from '../util/jsonschema'
import { prepareEntityForForm } from './prepareEntityForForm'

/**
 * @deprecated
 */
export const EntityMutateForm = (props: {
  schema: JSONSchema7
  entity?: Partial<IEntity>
  onSave: (entity: IEntity) => void
  children?: React.ReactElement<any>
}) => {
  assertIsString(props.schema.title, 'schema.title')
  assertIsObject(props.entity, 'entity')
  // Initiale Formulardaten - wenn wir ein neues Entity übergeben bekommen,
  // müssten wir das neu initialisieren

  // Defaults aus dem JSON Schema.
  const { entity: entityWithDefaults, cleanEntity } = prepareEntityForForm(props.schema, props.entity)
  const [currentFormData, setCurrentFormData] = useState<any>(entityWithDefaults)
  useEffect(() => {
    // We are called for a new entity, replace defaults
    // if (props.entity.designator !== currentFormData.designator) {
    setCurrentFormData(entityWithDefaults)
    // }
    // Das finde ich alles etwas fishy, muss ich zugeben
  }, [props.entity])

  if (!props.schema || !props.entity) {
    return null
  }

  // Aktuelle Änderungen: was wurde geändert und ist das "valid"?
  const valid = dataFitsSchema(currentFormData, props.schema)
  // das diff müssen wir gegen die Original-Input-Daten anwenden;
  // in `entity` sind ja schon die Defaults verarbeitet.
  const [diffVal, changedInfo] = _getDiff(props.entity, currentFormData, props.schema)

  return (
    <section aria-label="Datensatz bearbeiten">
      <Stack tokens={{ childrenGap: '0.5em' }}>
        <>
          <Form
            schema={props.schema}
            formData={currentFormData}
            liveValidate
            onChange={({ formData }) => {
              setCurrentFormData(formData)
            }}
            // onSubmit={({ formData }) => uebertragen(formData)}
          >
            {!valid ? (
              <MessageBar messageBarType={MessageBarType.warning}>
                Die Daten entsprechen nicht dem erwarteten Schema (siehe oben).
              </MessageBar>
            ) : null}
            <div data-testid="editpanelbutton" style={{ paddingTop: '0.5em' }}>
              {changedInfo === null ? (
                <PrimaryButton disabled text="Nichts geändert" type="submit" />
              ) : (
                <PrimaryButton
                  disabled={!valid}
                  text="Speichern"
                  type="submit"
                  onClick={(ev) => {
                    props.onSave(diffVal as IEntity)
                  }}
                />
              )}
            </div>
          </Form>
          {props.children}
          <p>Daten entsprechen dem Schema {props.schema.title}.</p>
          <section aria-label="diffVal" aria-hidden={true} style={{ display: 'none' }}>
            <span>{JSON.stringify(diffVal)}</span>
          </section>
          <section aria-label="currentFormData" aria-hidden={true} style={{ display: 'none' }}>
            <span>{JSON.stringify(currentFormData)}</span>
          </section>
          {changedInfo}
          <ReactJson
            name="Details"
            collapsed={true}
            displayObjectSize={false}
            src={{
              entity_property: props.entity,
              cleanEntity,
              original: props.entity,
              old: entityWithDefaults,
              new: cleanInputFromSchema(currentFormData, props.schema),
              schema: props.schema,
            }}
          />
        </>
      </Stack>
    </section>
  )
}

/** Die Formulardaten anhand der Schemadaten "aufräumen".
 * Besser wäre es, die GraphQL Definition vom Server
 * zu nehmen, aber da hab ich momentan keinen Ansatz zu.
 */
function cleanInputFromSchema(formData: Record<string, any>, schema: JSONSchema7) {
  const newFormData = { ...formData }

  // Properties, die nicht im Schema sind, schreiben wir nicht zurück
  const schemaProps = Object.keys(schema.properties as object)
  for (const key of Object.keys(newFormData)) {
    // hier fehlt die Rekursion ...
    if (!schemaProps.includes(key)) {
      delete newFormData[key]
    }
  }
  return cleanGqlInput(newFormData)
}

/** Gibt die geänderten Daten als Object und ein React-Element mit Erklärung zurück
 * */
function _getDiff(entity: object, currentFormData: object, schema: JSONSchema7) {
  let finalFormData = trimmAll(currentFormData)
  /// rjsf setzt leere Textfelder auf undefined, das reparieren wir hier
  finalFormData = jsonSchemaDataMerge({}, [jsonEmptyStrings(schema), fixStrings(entity, finalFormData)])
  finalFormData.definitions = undefined
  try {
    const diffVal = cleanDiff(entity, finalFormData)
    const changed =
      Object.keys(diffVal).length > 0 ? (
        <div>
          <Separator>geänderte Werte</Separator>
          <section aria-label="geänderte Werte">
            <JSONPretty data={diffVal} />
          </section>
        </div>
      ) : null
    return [diffVal, changed]
  } catch (e) {
    console.log(e, entity, finalFormData)
    return [{}, <div>diffVal failed</div>]
  }
}

/** trims all Strings within an Object
 * */
export function trimmAll(obj: any) {
  return transform(obj, (result: any, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || (value != null && typeof value === 'object')) {
      value = trimmAll(value)
    }
    // trim strings
    if (typeof value === 'string') {
      value = value.trim()
    }
    result[key] = value
  })
}

/** rjsf setzt leere Textfelder auf undefined, das reparieren wir hier
 * */
export function fixStrings(original: any, newObject: any) {
  return transform(
    { ...original },
    (result, value, key) => {
      // Recurse into arrays and objects.
      if (newObject?.[key] && (Array.isArray(value) || (value != null && typeof value === 'object'))) {
        value = fixStrings(value, newObject[key])
      }
      // fix strings
      if (typeof value === 'string' && !newObject[key]) {
        newObject[key] = ''
      }
      result[key] = newObject[key]
    },
    newObject
  )
}
