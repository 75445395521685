import { useQuery } from '@apollo/client'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { ComboBox, IComboBoxOption } from '@fluentui/react/lib/ComboBox'
import { DialogFooter } from '@fluentui/react/lib/Dialog'
import { Separator } from '@fluentui/react/lib/Separator'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { TextField } from '@fluentui/react/lib/TextField'
import carboneSDK from 'carbone-sdk-js'
import { saveAs } from 'file-saver'
import gql from 'graphql-tag'
import React, { useState } from 'react'

export const carbone = carboneSDK(process.env.REACT_APP_CARBONE_API_KEY)
carbone.setApiVersion(4)

const toOptions: IComboBoxOption[] = [
  { key: 'pdf', text: 'PDF' },
  { key: 'docx', text: 'DOCX' },
  { key: 'odt', text: 'ODT' },
  { key: 'txt', text: 'TXT' },
  { key: 'xml', text: 'XML' },
  { key: 'pptx', text: 'PPTX' },
  { key: 'xlxs', text: 'XLXS' },
  { key: 'ods', text: 'ODS' },
  { key: 'csv', text: 'CSV' },
]

const TEMPLATE_QUERY = gql`
  query ConfigTemplateQuery($cursor: String, $belegart: String) {
    nodes: h3ConfigTemplatePages(
      after: $cursor
      auchInaktive: false
      limit: 1000
      filter: { art: { eq: $belegart } }
      sort: { fields: [] }
    ) {
      edges {
        cursor
        node {
          designator
          art
          templateId
          ist_aktiv
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

/** PDF on demand erstellen
 *
 *  @deprecated
 */
export function PdfDialogInner({ data, onDismiss }) {
  const [renderError, setRenderError] = useState(null)
  const [rendering, setRendering] = useState(false)
  const [toOption, setToOption] = useState('pdf')
  const [templateOption, setTemplateOption] = useState('')
  useQuery(TEMPLATE_QUERY, {
    variables: { belegart: data.belegart },
    // Passenden templateID finden
    onCompleted: (d) => {
      for (const x of d.nodes.edges) {
        if (x.node.art === data.belegart) {
          setTemplateOption(x.node.templateId)
        }
      }
    },
  })

  async function startRender() {
    setRendering(true)
    setRenderError(null)
    // Das sollte wie huwawi3-py/components/berlege/render.py funktionieren
    const carboneReply = await carbone.render(templateOption, {
      data,
      convertTo: toOption,
      lang: 'de-de',
      currency: {
        source: 'EUR',
        target: 'EUR',
        rates: {
          EUR: 1,
          CENT: 0.01,
        },
      },
    })
    saveAs(carboneReply.content, `${data.designator}-${carboneReply.name}`)
    setRendering(false)
  }

  return (
    <>
      <div>
        <TextField
          label="Rendering Template"
          iconProps={{ iconName: 'ChartTemplate' }}
          value={templateOption}
          placeholder="carbone.io templateID nicht konfiguriert"
          onChange={(event, newValue?: string) => {
            setTemplateOption(newValue)
          }}
        />
        <ComboBox
          selectedKey={toOption}
          label="Erstelle Rendering im Format"
          options={toOptions}
          onChange={(event, option) => {
            setToOption(option.key as string)
          }}
        />
      </div>
      <Separator></Separator>
      <div>
        {rendering ? <Spinner size={SpinnerSize.xSmall} /> : null}
        {renderError ? <p>{`Fehler: ${renderError}`}</p> : null}
      </div>

      <DialogFooter>
        <PrimaryButton onClick={startRender} text="Rendern" disabled={rendering} />
        <DefaultButton onClick={onDismiss} text="Schießen" />
      </DialogFooter>
    </>
  )
}
