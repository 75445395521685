// Anzeige von SKU Daten
// Created by Dr. Maximillian Dornseif 2021
// Copyright 2020-2022 HUDORA

import gql from 'graphql-tag'
import React from 'react'
import { navigate } from 'wouter/use-location'

import {
  BESTANDSBUCHSUMME_FRAGMENT,
  BESTANDSBUCH_FRAGMENT,
  BESTELLPOSITION_FRAGMENT,
  SKU_FRAGMENT,
  ZUTEILUNG_FRAGMENT,
} from '../fragments'
import { IEntitySubPage } from '../lib/components/EntityPage'
import { KindListe } from '../lib/components/KindListe'
import { KindPage } from '../lib/components/KindPage'

export const IcSkusApp = () => {
  const subPages: IEntitySubPage[] = [
    {
      title: 'Bestände',
      path: 'bestand',
      component: (
        <KindListe
          className="authEnforced"
          schemaName={'HwIcBestandsbuchSumme'}
          listQuery={SKU_BESTAND_LIST_QUERY}
          title={''}
        />
      ),
    },
    {
      title: 'Bestellungen',
      path: 'bestellungen',
      component: <KindListe schemaName={'HwBestellPosition'} listQuery={BESTELL_LIST_QUERY} title={''} />,
    },
    {
      title: 'Zuteilungen',
      path: 'zuteilungen',
      component: <KindListe schemaName={'HwIcZuteilung'} listQuery={ZUTEILUNG_LIST_QUERY} title={''} />,
    },
    {
      title: 'Bestandsbuch',
      path: 'bestandsbuch1',
      component: (
        <KindListe
          schemaName={'HwIcBestandsbuchEintrag'}
          listQuery={BESTANDSBUCH_LIST_QUERY}
          title={''}
          hideExcept={[
            'lager',
            'datum',
            'buchungsnr',
            'menge',
            'artnr',
            'verbucht',
            'menge_nach_verbuchung',
            'beleg',
            'vorgang',
            'ausloeser',
            'index',
            'verbucht_at',
          ]}
        />
      ),
    },
  ]

  return (
    <KindPage
      basePath={'/ic'}
      componentPath={'sku'}
      schemaName={'H3Sku'}
      title={'SKUs (Lagerinhalt)'}
      subPages={subPages}
      listQuery={SKU_LIST_QUERY}
      entityQuery={ENTITY_QUERY}
      suggestionQuery={SUGGESTIONS_QUERY}
      entityCommandBarItems={[
        {
          key: 'eap',
          text: 'im eAP',
          ariaLabel: 'SKU-eAP',
          iconProps: {
            iconName: 'ProductList',
          },
          iconOnly: false,
          onClick: (e) => {
            navigate(window.location.pathname.replace('/ic/sku/', '/eap/sku/'))
          },
        },
      ]}
    />
  )
}

function KindListeMitSlash(props) {
  return <KindListe {...props} nr={props.nr.replace('x', '/')} />
}
function KindListeOhneVersion(props) {
  const [base, _version] = props.nr.split('x', 2)
  return <KindListe {...props} nr={base} />
}

const SUGGESTIONS_QUERY = gql`
  query SkuSuggestionsQuery($term: String!) {
    fulltextSearch: h3SkuSearch(term: $term) {
      id
      designator
      title
      score
    }
  }
`
export const SKU_LIST_QUERY = gql`
  query SkuList($cursor: String) {
    nodes: h3SkuPages(after: $cursor, filter: { ist_aktiv: { eq: true } }) {
      edges {
        cursor
        node {
          ...skuFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SKU_FRAGMENT}
`

export const ENTITY_QUERY = gql`
  query skuLesen($nr: ID!) {
    entity: h3Sku(designator: $nr) {
      ...skuFragment
    }
  }
  ${SKU_FRAGMENT}
`

export const SKU_BESTAND_LIST_QUERY = gql`
  query PBSkuQuery($nr: ID!) {
    entity: h3Sku(designator: $nr) {
      id
      master {
        skuList {
          nodes: bestaende {
            ...bestandsbuchsummeFragment
          }
        }
      }
    }
  }
  ${BESTANDSBUCHSUMME_FRAGMENT}
`
export const BESTELL_LIST_QUERY = gql`
  query PBestSkuQuery($nr: ID!) {
    entity: h3Sku(designator: $nr) {
      id
      nodes: bestellPositionList {
        ...bestellpositionFragment
      }
    }
  }
  ${BESTELLPOSITION_FRAGMENT}
`
export const ZUTEILUNG_LIST_QUERY = gql`
  query PZSkuQuery($nr: ID!) {
    entity: h3Sku(designator: $nr) {
      id
      nodes: icZuteilungList {
        ...zuteilungFragment
      }
    }
  }
  ${ZUTEILUNG_FRAGMENT}
`

export const BESTANDSBUCH_LIST_QUERY = gql`
  query PBQuery1($nr: ID!) {
    entity: h3Sku(designator: $nr) {
      id
      nodes: icBestandsbuchEintragList {
        ...bestandsbuchFragment
      }
    }
  }
  ${BESTANDSBUCH_FRAGMENT}
`
