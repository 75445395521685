// huWaWi als Single Page Application
// Created in March 2020 by Maximillian Dornseif
// Copyright Cyberlogi, HUDORA 2020-2022

import './App.css'

import { ConfirmProvider } from 'material-ui-confirm'
import React, { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { installLicense } from 'react-use-aggrid-enterprise'
import { Route } from 'wouter'

import { AaaMainChecker } from './AaaChecker'
import { BelegeApp } from './Belege/BelegeApp'
import { DocApp } from './Documentation/DocApp'
import { EapApp } from './eAP/EapApp'
import { IcApp } from './Ic/IcApp'
import { SearchPageApp } from './SearchPageApp.jsx'
import { SearchWidget } from './SearchWidget'
import { StammdatenApp } from './Stammdaten/StammdatenApp'
import { WaWiApp } from './WaWi/WaWiApp'

const DevPageLoader = React.lazy(() => import('./DevPageApp'))
const ReportingAppLoader = React.lazy(() => import('./Reporting/ReportingApp'))
installLicense(process.env.REACT_APP_AGGRID_LICENSE_KEY)

export const FEATURES = {
  artikel: true,
  belege: true,
  einkauf: false,
  eap: true,
  fob: false,
  kunden: false,
  ic: true,
  reporting: true,
  stamm: true,
  verbaende: true,
  wawi: true,
  doc: true,
}

/** Die eigentliche App */
export function App(props) {
  return (
    <ConfirmProvider
      defaultOptions={{ title: 'Sicher?', confirmationText: 'Ok', cancellationText: 'Abbrechen' }}
    >
      <SearchWidget />
      <Toaster
        // See https://react-hot-toast.com/docs/toaster
        position="top-center"
        toastOptions={{
          // Default options for specific types
          success: { duration: 5000 },
        }}
      />
      <AaaMainChecker>
        <Route path="/doc/:p*">
          <DocApp />
        </Route>
        <Route path="/dev/:p*">
          <Suspense fallback={<div>Lade Modul Dev…</div>}>
            <DevPageLoader client={props.apClient} />
          </Suspense>
        </Route>
        <Route path="/wawi/:p*">
          <WaWiApp />
        </Route>
        <Route path="/belege/:p*">
          <BelegeApp />
        </Route>
        <Route path="/ic/:p*">
          <IcApp />
        </Route>
        <Route path="/reporting/:p*">
          <Suspense fallback={<div>Lade Modul Reporting…</div>}>
            <ReportingAppLoader />
          </Suspense>
        </Route>
        <Route path="/stamm/:p*">
          <StammdatenApp />
        </Route>
        <Route path="/eap/:p*">
          <EapApp />
        </Route>
        <Route path="/">
          <DefaultApp default />
        </Route>
      </AaaMainChecker>
    </ConfirmProvider>
  )
}

function DefaultApp(props: any) {
  return (
    <div>
      <SearchPageApp />
    </div>
  )
}
